import React from "react";
// import Banner from "../components/Banner/Banner.jsx";
import Layout from "../Layout.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import imagem from "../assets/images/reskilling.png";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";

export default function ComecePorAqui() {
	const routeToCertificacao = () => window.open("https://certificado-labora.thinkific.com/collections/courses", "_blank");
	return (
		<>
			{/* <header>
			<Banner title="Reskilling" description="Página em desenvolvimento" />
			</header> */}

			{/* <Layout title="Reskilling" description="Página em desenvolvimento"> */}

			{/* <div className="container-perfil pb20"> */}
				{/* <div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div> */}
				{/* <div className="container-box"> */}
					<h1 className="reinvencao_explicacao pt60">A Labora proporciona certificações identitárias para você
						navegar e se reinventar no atual momento da sua vida em relação ao cenário do mundo e do mercado.</h1>
					<p className="reinvencao_explicacao">- Para começar a sua jornada conosco, acesse nossas certificações de
						reskilling, clique no botão no fim desta página para acessar.</p>
					<p className="reinvencao_explicacao">- Faça o cadastro na plataforma de ensino Labora com o mesmo email
						e senha que você usou para estar aqui.</p>
					{/* <h2 className="reinvencao_explicacao">Você está aqui:</h2> */}
					<img className="imagem_colmeia pb20" src={imagem} alt="Colmeia" />
					<h2 className="reinvencao_explicacao pb20 pt20">Comece pelo menu Reskilling e seja muito bem-vindo.</h2>
					<StyledBtn
						// className="btn-banner"
						variant="contained"
						color="secondary"
						onClick={routeToCertificacao}
					>
						Comece por aqui
					</StyledBtn>
				{/* </div> */}
			{/* </div> */}
			{/* </Layout> */}
			{/* <Footer /> */}
		</>
	);
}
