import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";

export default function Dashboard() {
	return (
		<>
			{/* <header>
				<Banner title="Dashboard" description="Página em desenvolvimento" />
			</header>
			<div className="container-perfil">
				<div className="container-menu pb150">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box pb150"> */}
					<h1 className="reinvencao_explicacao pt60">Dentro dos projetos que você já foi alocado a labora demonstrará
						por aqui sua performance no projeto e o andamento do cliente no âmbito geral.</h1>
					<p className="reinvencao_explicacao">Mais detalhes em breve… </p>
				{/* </div>
			</div>
			<Footer /> */}
		</>
	);
}
