const EventoPadrao = [
    // { id: 1, title: 'Ano Novo', start: new Date("2024-01-01T00:00"), end: new Date("2024-01-01T00:00"), color: "#1bb91b", },
    // { id: 2, title: 'SP Aniversário', start: new Date("2024-01-25T00:00"), end: new Date("2024-01-25T00:00"), color: "#1bb91b", },
    // { id: 3, title: 'Carnaval', start: new Date("2024-02-13T00:00"), end: new Date("2024-02-13T00:00"), color: "#1bb91b", },
    // { id: 4, title: 'Paixão de Cristo', start: new Date("2024-03-29T00:00"), end: new Date("2024-03-29T00:00"), color: "#1bb91b", },
    // { id: 5, title: 'Tiradentes', start: new Date("2024-04-21T00:00"), end: new Date("2024-04-21T00:00"), color: "#1bb91b", },
    // { id: 6, title: 'Dia do Trabalho', start: new Date("2024-05-01T00:00"), end: new Date("2024-05-01T00:00"), color: "#1bb91b", },
    // { id: 7, title: 'Corpus Christi', start: new Date("2024-05-30T00:00"), end: new Date("2024-05-30T00:00"), color: "#1bb91b", },
    // { id: 8, title: 'Independência do Brasil', start: new Date("2024-09-07T00:00"), end: new Date("2024-09-07T00:00"), color: "#1bb91b", },
    // { id: 9, title: 'Nossa Senhora Aparecida', start: new Date("2024-10-12T00:00"), end: new Date("2024-10-12T00:00"), color: "#1bb91b", },
    // { id: 10, title: 'Finados', start: new Date("2024-11-02T00:00"), end: new Date("2024-11-02T00:00"), color: "#1bb91b", },
    // { id: 11, title: 'Proclamação da República', start: new Date("2024-11-15T00:00"), end: new Date("2024-11-15T00:00"), color: "#1bb91b", },
    // { id: 12, title: 'Consciência Negra', start: new Date("2024-11-20T00:00"), end: new Date("2024-11-20T00:00"), color: "#1bb91b", },
    // { id: 13, title: 'Natal', start: new Date("2024-12-25T00:00"), end: new Date("2024-12-25T00:00"), color: "#1bb91b", }
];
export default EventoPadrao;
