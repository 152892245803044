import React from "react";
import logolabora2 from "../../assets/images/laboralogo2.png";
import logolaborab from "../../assets/images/certified-b-corp-logo.png";
import logoashoka from "../../assets/images/ashoka.png";
import logocubo from "../../assets/images/cubo-itau.png";
import logolab60 from "../../assets/images/lab60.png"
import "./Footer.css";
import { IconContext } from "react-icons";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="logo-footer">
          <img className="img-logo1" src={logolabora2} alt="logo labora tech" />
          <p className="footer-titulo">1ª plataforma de tecnologia para inclusão produtiva e trabalho flex do Brasil</p>
        </div>
        <div className="container-footer">
          {/* <div className="labora-links">
            <h2 className="footer-h2">Navegue</h2>
            <a href="https://www.labora.tech/sou-empresa" className="link-footer">Sou empresa</a><br /><br />
            <a href="https://www.labora.tech/sou-talento" className="link-footer">Sou talento</a><br /><br />
            <a href="https://www.labora.tech/sobre-nos" className="link-footer">Sobre nós</a><br /><br />
            <a href="https://www.labora.tech/nossa-causa" className="link-footer">Nossa causa</a><br /><br />
            <a href="https://contato.labora.tech/lp-comunidade-whatsapp-recorrente" className="link-footer">Entrar na Comunidade</a><br /><br />
            <a href="https://www.labora.tech/blog" className="link-footer">Blog</a>
          </div> */}
          <div className="labora-conexoes">
            {/* <div>
              <h2 className="footer-h2">Acesse</h2>
              <a href="https://w3.labora.tech" className="link-footer">Plataforma Labora</a><br /><br />
              <a href="https://www.labora.tech/politica-de-privacidade" className="link-footer">Polítiva de Privacidade</a><br /><br />
              </div> */}
            <div>
              <h2 className="footer-h2">Conecte-se</h2>
              <p>Nossas redes sociais</p>
              <div>
                <div id="social-icons">
                  <IconContext.Provider value={{ className: "top-react-icons" }}>
                    <a href="https://www.instagram.com/labora.tech/" target="_blank" rel="noreferrer">
                      <BsInstagram />
                    </a>
                    <a href="https://www.facebook.com/comunidadeLabora" target="_blank" rel="noreferrer">
                      <BsFacebook />
                    </a>
                    <a href="https://www.youtube.com/channel/UC7TOZ8bmmrOh5-h1Zc2EyPg" target="_blank" rel="noreferrer">
                      <BsYoutube />
                    </a>
                    <a href="https://www.linkedin.com/company/labora-tech/" target="_blank" rel="noreferrer">
                      <BsLinkedin />
                    </a>
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="labora-certificacoes"> */}
          <div className="labora-links">
            <h2 className="footer-h2">Reconhecimentos</h2>
            <img className="img-logo2" src={logolaborab} alt="foto certificação B" />
            <img className="img-logo2" src={logoashoka} alt="foto Ashoka" />
            <img className="img-logo2" src={logocubo} alt="foto Cubo Itaú" />
          </div>
          <div className="labora-certificacoes">
            <h2 className="footer-h2">Organização sustentadora do:</h2>
            <img className="img-logo3" src={logolab60} alt="foto Lab60+" />
          </div>
          {/* </div> */}
        </div>
      </footer>
    </>
  );
}
