import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, AuthContext } from "../contexts/auth";
import { UserProvider } from "../contexts/userUpdate";
import Layout from "../Layout";
import Home from "../pages/Home";
import Perfil from "../pages/Perfil.jsx";
import Oportunidades from "../pages/Oportunidades.jsx";
import Login from "../pages/Login";
import PasswordReset from "../pages/PasswordReset";
import Cadastro from "../pages/Cadastro";
import NoPage from "../pages/NoPage";
import UpdatePassword from "../pages/UpdatePassword";
import UpdateTempPass from "../pages/UpdateTempPass";
import JornadaLabora from "../pages/JornadaLabora";
import ProcSeletivos from "../pages/ProcSeletivos";
import JornadasRealizadas from "../pages/JornadasRealizadas";
import Disponibilidade from "../pages/Disponibilidade.jsx";
import Mei from "../pages/Mei.jsx";
import Roleplays from "../pages/Roleplays.jsx";
import Ranking from "../pages/Ranking.jsx";
import Agenda from "../pages/Agenda.jsx";
import AppLabora from "../pages/AppLabora.jsx";
import Onboarding from "../pages/Onboarding.jsx";
import Dashboard from "../pages/Dashboard.jsx";
import Financeiro from "../pages/Financeiro.jsx";
import Reskilling from "../pages/Reskilling.jsx";
import FlexLabora from "../pages/FlexLabora.jsx";
import Eventos from "../pages/Eventos.jsx";
import MoedasLabora from "../pages/MoedasLabora.jsx";
import HubLabora from "../pages/HubLabora.jsx";
import Formatura from "../pages/Formatura.jsx";
import BemVindos from "../pages/BemVindos.jsx";
import Mentorias from "../pages/Mentorias.jsx";
import Advocacy from "../pages/Advocacy.jsx";
import CulturaLabora from "../pages/CulturaLabora.jsx";
import Especializacoes from "../pages/Especializacoes.jsx";
import TrabalhoFlex from "../pages/TrabalhoFlex.jsx";
import ComecePorAqui from "../pages/ComecePorAqui.jsx";
// import Empresas from "../pages/Empresas";
// import Certificados from "../pages/Certificados";

const Private = ({ children }) => {
  const { authenticated, loading } = useContext(AuthContext);
  if (loading) {
    return <div className="loading"> Carregando... </div>;
  }

  if (!authenticated) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

const Rotas = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <Routes>
            <Route
              exact
              path="/home"
              element={
                <Private>
                  <Layout title="Plataforma Labora" description="Plataforma Labora">
                    <Home />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/perfil"
              element={
                <Private>
                  <Layout title="Meu Perfil" description="Seu perfil no Labora">
                    <Perfil />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/oportunidades"
              element={
                <Private>
                  <Layout title="Oportunidades" description="Oportunidades Labora Tech.">
                    <Oportunidades />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/updatePassword"
              element={
                <Private>
                  <Layout title="Alterar Senha" description="Perfil Labora Tech.">
                    <UpdatePassword />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/updateTempPass"
              element={
                <Private>
                  <Layout title="Atualizar Senha Temporária" description="Atualize sua senha temporária">
                    <UpdateTempPass />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/disponibilidade"
              element={
                <Private>
                  <Layout title="Disponibilidade" description="Disponibilidade Labora Tech.">
                    <Disponibilidade />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/jornadaLabora"
              element={
                <Private>
                  <Layout title="Certificações" description="Jornada Labora Tech.">
                    <JornadaLabora />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/procSeletivos"
              element={
                <Private>
                  <Layout title="Processos Seletivos" description="Processos Seletivos Labora Tech.">
                    <ProcSeletivos />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/jornadasRealizadas"
              element={
                <Private>
                  <Layout title="Minha jornada" description="Empresas Labora Tech.">
                    <JornadasRealizadas />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/mei"
              element={
                <Private>
                  <Layout title="Como abrir uma MEI" description="Página em desenvolvimento">
                    <Mei />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/roleplays"
              element={
                <Private>
                  <Layout title="Roleplays/Avaliações" description="Página em desenvolvimento">
                    <Roleplays />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/ranking"
              element={
                <Private>
                  <Layout title="Ranking" description="Página em desenvolvimento">
                    <Ranking />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/agenda"
              element={
                <Private>
                  <Layout title="Agenda de eventos" description="Página em desenvolvimento">
                    <Agenda />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/appLabora"
              element={
                <Private>
                  <Layout title="App Labora" description="Página em desenvolvimento">
                    <AppLabora />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/onboarding"
              element={
                <Private>
                  <Layout title="Onboarding dos projetos" description="Página em desenvolvimento">
                    <Onboarding />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Private>
                  <Layout title="Dashboard" description="Página em desenvolvimento">
                    <Dashboard />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/financeiro"
              element={
                <Private>
                  <Layout title="Meu financeiro" description="Página em desenvolvimento">
                    <Financeiro />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/reskilling"
              element={
                <Private>
                  <Layout title="Reskilling" description="Página em desenvolvimento">
                    <Reskilling />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/flexLabora"
              element={
                <Private>
                  <Layout title="Flex Labora" description="Página em desenvolvimento">
                    <FlexLabora />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/eventos"
              element={
                <Private>
                  <Layout title="Eventos" description="Página em desenvolvimento">
                    <Eventos />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/moedasLabora"
              element={
                <Private>
                  <Layout title="Moedas Labora" description="Gerencie suas moedas Labora">
                    <MoedasLabora />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/hubLabora"
              element={
                <Private>
                  <Layout title="Hub Labora" description="Página em desenvolvimento">
                    <HubLabora />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/formatura"
              element={
                <Private>
                  <Layout title="Formatura" description="Página em desenvolvimento">
                    <Formatura />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/bemVindos"
              element={
                <Private>
                  <Layout title="Bem-Vindos" description="Página em desenvolvimento">
                    <BemVindos />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/mentorias"
              element={
                <Private>
                  <Layout title="Mentorias" description="Página em desenvolvimento">
                    <Mentorias />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/advocacy"
              element={
                <Private>
                  <Layout title="Advocacy" description="Página em desenvolvimento">
                    <Advocacy />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/culturaLabora"
              element={
                <Private>
                  <Layout title="Cultura Labora" description="Explore a cultura Labora">
                    <CulturaLabora />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/especializacoes"
              element={
                <Private>
                  <Layout title="Especializações" description="Página em desenvolvimento">
                    <Especializacoes />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/trabalhoFlex"
              element={
                <Private>
                  <Layout title="Trabalho Flex" description="Página em desenvolvimento">
                    <TrabalhoFlex />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/comecePorAqui"
              element={
                <Private>
                  <Layout title="Reskilling" description="Página em desenvolvimento">
                    <ComecePorAqui />
                  </Layout>
                </Private>
              }
            />
            {/* Rotas comentadas */}
            {/* <Route
              path="/empresas"
              element={
                <Private>
                  <Layout title="Empresas" description="Veja as empresas parceiras">
                    <Empresas />
                  </Layout>
                </Private>
              }
            />
            <Route
              path="/certificados"
              element={
                <Private>
                  <Layout title="Certificados" description="Gerencie seus certificados">
                    <Certificados />
                  </Layout>
                </Private>
              }
            /> */}
            <Route path="*" element={<NoPage />} />
            <Route exact path="/" element={<Login />} />
            <Route path="/passwordReset" element={<PasswordReset />} />
            <Route path="/cadastro" element={<Cadastro />} />
          </Routes>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Rotas;
























// import React, { useContext } from "react";
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import { AuthProvider, AuthContext } from "../contexts/auth";
// import { UserProvider } from "../contexts/userUpdate";
// import Home from "../pages/Home";
// import Perfil from "../pages/Perfil.jsx";
// import Oportunidades from "../pages/Oportunidades.jsx";
// import Login from "../pages/Login";
// import PasswordReset from "../pages/PasswordReset";
// import Cadastro from "../pages/Cadastro";
// import NoPage from "../pages/NoPage";
// import UpdatePassword from "../pages/UpdatePassword";
// import UpdateTempPass from "../pages/UpdateTempPass";
// import JornadaLabora from "../pages/JornadaLabora";
// import ProcSeletivos from "../pages/ProcSeletivos";
// import JornadasRealizadas from "../pages/JornadasRealizadas";
// import Disponibilidade from "../pages/Disponibilidade.jsx";
// import Mei from "../pages/Mei.jsx";
// import Roleplays from "../pages/Roleplays.jsx";
// import Ranking from "../pages/Ranking.jsx";
// import Agenda from "../pages/Agenda.jsx";
// import AppLabora from "../pages/AppLabora.jsx";
// import Onboarding from "../pages/Onboarding.jsx";
// import Dashboard from "../pages/Dashboard.jsx";
// import Financeiro from "../pages/Financeiro.jsx";
// import Reskilling from "../pages/Reskilling.jsx";
// import FlexLabora from "../pages/FlexLabora.jsx";
// import Eventos from "../pages/Eventos.jsx";
// import MoedasLabora from "../pages/MoedasLabora.jsx";
// import HubLabora from "../pages/HubLabora.jsx";
// import Formatura from "../pages/Formatura.jsx";
// import BemVindos from "../pages/BemVindos.jsx";
// import Mentorias from "../pages/Mentorias.jsx";
// import Advocacy from "../pages/Advocacy.jsx";
// import CulturaLabora from "../pages/CulturaLabora.jsx";
// import Especializacoes from "../pages/Especializacoes.jsx";
// import TrabalhoFlex from "../pages/TrabalhoFlex.jsx";
// import ComecePorAqui from "../pages/ComecePorAqui.jsx";
// // import Empresas from "../pages/Empresas";
// // import Certificados from "../pages/Certificados";

// const Rotas = () => {
//   const Private = ({ children }) => {
//     const { authenticated, loading } = useContext(AuthContext);
//     if (loading) {
//       return <div className="loading"> Carregando... </div>;
//     }
        
//     if (!authenticated) {
//       return <Navigate to="/" />;
//     } else {
//       return children;
//     }
//   };
//   return (
//     <BrowserRouter>
//       <AuthProvider>
//         <UserProvider>
//           <Routes>
//             <Route exact path="/home" element={ 
//               <Private>
//                 <Home />
//               </Private>
//               }
//             />
//             <Route path="/perfil" element={
//               <Private>
//                 <Perfil />
//               </Private>
//               }
//             />
//             <Route
//               path="/oportunidades" element={
//                 <Private>
//                   <Oportunidades />
//                 </Private>
//               }
//             />
//             <Route
//               path="/updatePassword" element={
//                 <Private>
//                   <UpdatePassword />
//                 </Private>
//               }
//             />
//             <Route
//               path="/updateTempPass" element={
//                 <Private>
//                   <UpdateTempPass />
//                 </Private>
//               }
//             />
//             <Route
//               path="/disponibilidade" element={
//                 <Private>
//                   <Disponibilidade />
//                 </Private>
//               }
//             />
//             <Route
//               path="/jornadaLabora" element={
//                 <Private>
//                   <JornadaLabora />
//                 </Private>
//               }
//             />
//             <Route
//               path="/procSeletivos" element={
//                 <Private>
//                   <ProcSeletivos />
//                 </Private>
//               }
//             />
//             <Route
//               path="/jornadasRealizadas" element={
//                 <Private>
//                   <JornadasRealizadas />
//                 </Private>
//               }
//             />
//             <Route
//               path="/mei" element={
//                 <Private>
//                   <Mei />
//                 </Private>
//               }
//             />
//             <Route
//               path="/roleplays" element={
//                 <Private>
//                   <Roleplays />
//                 </Private>
//               }
//             />
//             <Route
//               path="/ranking" element={
//                 <Private>
//                   <Ranking />
//                 </Private>
//               }
//             />
//             <Route
//               path="/agenda" element={
//                 <Private>
//                   <Agenda />
//                 </Private>
//               }
//             />
//             <Route
//               path="/appLabora" element={
//                 <Private>
//                   <AppLabora />
//                 </Private>
//               }
//             />
//             <Route
//               path="/onboarding" element={
//                 <Private>
//                   <Onboarding />
//                 </Private>
//               }
//             />
//             <Route
//               path="/dashboard" element={
//                 <Private>
//                   <Dashboard />
//                 </Private>
//               }
//             />
//             <Route
//               path="/Financeiro" element={
//                 <Private>
//                   <Financeiro />
//                 </Private>
//               }
//             />
//             <Route
//               path="/reskilling" element={
//                 <Private>
//                   <Reskilling />
//                 </Private>
//               }
//             />
//             <Route
//               path="/flexLabora" element={
//                 <Private>
//                   <FlexLabora />
//                 </Private>
//               }
//             />
//             <Route
//               path="/eventos" element={
//                 <Private>
//                   <Eventos />
//                 </Private>
//               }
//             />
//             <Route
//               path="/moedasLabora" element={
//                 <Private>
//                   <MoedasLabora />
//                 </Private>
//               }
//             />
//             <Route
//               path="/hubLabora" element={
//                 <Private>
//                   <HubLabora />
//                 </Private>
//               }
//             />
//             <Route
//               path="/formatura" element={
//                 <Private>
//                   <Formatura />
//                 </Private>
//               }
//             />
//             <Route
//               path="/bemVindos" element={
//                 <Private>
//                   <BemVindos />
//                 </Private>
//               }
//             />
//             <Route
//               path="/mentorias" element={
//                 <Private>
//                   <Mentorias />
//                 </Private>
//               }
//             />
//             <Route
//               path="/advocacy" element={
//                 <Private>
//                   <Advocacy />
//                 </Private>
//               }
//             />
//             <Route
//               path="/culturaLabora" element={
//                 <Private>
//                   <CulturaLabora />
//                 </Private>
//               }
//             />
//             <Route
//               path="/especializacoes" element={
//                 <Private>
//                   <Especializacoes />
//                 </Private>
//               }
//             />
//             <Route
//               path="/trabalhoFlex" element={
//                 <Private>
//                   <TrabalhoFlex />
//                 </Private>
//               }
//             />
//             <Route
//               path="/comecePorAqui" element={
//                 <Private>
//                   <ComecePorAqui />
//                 </Private>
//               }
//             />
//             {/* <Route
//               path="/empresas" element={
//                 <Private>
//                   <Empresas />
//                 </Private>
//               }
//             /> */}
//             {/* <Route
//               path="/certificados" element={
//                 <Private>
//                   <Certificados />
//                 </Private>
//               }
//             /> */}
//             {" "}

//             <Route path="*" element={<NoPage />} />{" "}
//             <Route exact path="/" element={<Login />} />{" "}
//             <Route path="/passwordReset" element={<PasswordReset />} />{" "}
//             <Route path="/cadastro" element={<Cadastro />} />{" "}
//             {/* <Route path="*" element={<NoPage />} />{" "} */}
//           </Routes>{" "}
//         </UserProvider>{" "}
//       </AuthProvider>{" "}
//     </BrowserRouter>
//   );
// };

// export default Rotas;
