import React from "react";
import "./SocialMedias.css";

let ano = new Date().getFullYear();

export default function SocialMedias() {
  return (
    <div className="rodape">
      <p>Termos de uso</p>
      <p>@ {ano} | Labora | Marca e direitos registrados</p>
    </div>
  );
}
