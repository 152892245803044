import React, { useCallback, useContext, useState, useEffect } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { StyledButton } from "../StyledButton/StyledButton";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import "./MenuPerfil.css";
import MenuContext from './MenuContext.jsx';


export default function MenuPerfil() {

	const { submenus, toggleSubMenu, selectedOption, handleSubmenuClick } = useContext(MenuContext);
	let navigate = useNavigate();
	const { closeAllSubMenus } = useContext(MenuContext);
	const routeToHome = useCallback(() => { closeAllSubMenus(); navigate("/home"); }, [navigate, closeAllSubMenus]);
	const routeToPerfil = useCallback(() => navigate("/perfil"), [navigate]);
	const routeToOportunidades = useCallback(() => navigate("/oportunidades"), [navigate]);
	const routeToUpdatePassword = useCallback(() => navigate("/updatePassword"), [navigate]);

	// const routeToCertificacao = useCallback(() => window.open("https://certificado-labora.thinkific.com/", "_blank"), []);

	const routeToJornadaLabora = useCallback(() => navigate("/jornadaLabora"), [navigate]);
	const routeToProcSeletivos = useCallback(() => navigate("/procSeletivos"), [navigate]);
	const routeToJornadasRealizadas = useCallback(() => navigate("/jornadasRealizadas"), [navigate]);
	const routeToDisponibilidade = useCallback(() => navigate("/disponibilidade"), [navigate]);
	const routeToLab60 = useCallback(() => window.open("https://www.lab60.org/", "_blank"), []);
	const routeToMei = useCallback(() => navigate("/mei"), [navigate]);
	const routeToRoleplays = useCallback(() => navigate("/roleplays"), [navigate]);
	const routeToRanking = useCallback(() => navigate("/ranking"), [navigate]);
	const routeToAgenda = useCallback(() => navigate("/agenda"), [navigate]);
	const routeToAppLabora = useCallback(() => navigate("/appLabora"), [navigate]);
	const routeToOnboarding = useCallback(() => navigate("/onboarding"), [navigate]);
	const routeToDashboard = useCallback(() => navigate("/dashboard"), [navigate]);
	const routeToFinanceiro = useCallback(() => navigate("/financeiro"), [navigate]);
	const routeToReskilling = useCallback(() => navigate("/reskilling"), [navigate]);
	const routeToFlexLabora = useCallback(() => navigate("/flexLabora"), [navigate]);
	const routeToEventos = useCallback(() => navigate("/eventos"), [navigate]);
	const routeToMoedasLabora = useCallback(() => navigate("/moedasLabora"), [navigate]);
	const routeToHubLabora = useCallback(() => navigate("/hubLabora"), [navigate]);
	const routeToFormatura = useCallback(() => navigate("/formatura"), [navigate]);
	const routeToBemVindos = useCallback(() => navigate("/bemVindos"), [navigate]);
	const routeToMentorias = useCallback(() => navigate("/mentorias"), [navigate]);
	const routeToAdvocacy = useCallback(() => navigate("/advocacy"), [navigate]);
	const routeToCulturaLabora = useCallback(() => navigate("/culturaLabora"), [navigate]);
	const routeToEspecializacoes = useCallback(() => navigate("/especializacoes"), [navigate]);
	const routeToTrabalhoFlex = useCallback(() => navigate("/trabalhoFlex"), [navigate]);
	const routeToComecePorAqui = useCallback(() => navigate("/comecePorAqui"), [navigate]);

	const { logout } = useContext(AuthContext);
	const handleLogout = () => {
		logout();
	};

	const [shouldNavigate, setShouldNavigate] = useState(false); // Estado para controlar a navegação

	const handleSubmenuClickWithNavigation = (option) => {
		handleSubmenuClick(option); // Atualiza o estado de selectedOption
		// Navegação baseada na opção selecionada
		if (option === 'Comece por aqui') {
			routeToComecePorAqui();
		} else if (option === 'Meus Certificados') {
			routeToJornadaLabora();
		} else if (option === 'Aprenda como abrir uma MEI') {
			routeToMei();
		} else if (option === 'Especializações') {
			routeToEspecializacoes();
		} else if (option === 'Oportunidades') {
			routeToOportunidades();
		} else if (option === 'Agenda de eventos') {
			routeToAgenda();
		} else if (option === 'Roleplays') {
			routeToRoleplays();
		} else if (option === 'Ranking') {
			routeToRanking();
		} else if (option === 'Trabalho Flex') {
			routeToTrabalhoFlex();
		} else if (option === 'Disponibilidade') {
			routeToDisponibilidade();
		} else if (option === 'Baixe o app') {
			routeToAppLabora();
		} else if (option === 'Onboarding dos projetos') {
			routeToOnboarding();
		} else if (option === 'Dashboard do projeto') {
			routeToDashboard();
		} else if (option === 'Minha jornada') {
			routeToJornadasRealizadas();
		} else if (option === 'Meu financeiro') {
			routeToFinanceiro();
		} else if (option === 'Reskilling') {
			routeToReskilling();
		} else if (option === 'Processos Seletivos') {
			routeToProcSeletivos();
		} else if (option === 'Flex Labora') {
			routeToFlexLabora();
		} else if (option === 'Meu perfil') {
			routeToPerfil();
		} else if (option === 'Alterar senha') {
			routeToUpdatePassword();
		} else if (option === 'Cultura Labora') {
			routeToCulturaLabora();
		} else if (option === 'Eventos') {
			routeToEventos();
		} else if (option === 'Moedas Labora') {
			routeToMoedasLabora();
		} else if (option === 'Lab60+') {
			routeToLab60();
		} else if (option === 'Hub Labora') {
			routeToHubLabora();
		} else if (option === 'Formatura') {
			routeToFormatura();
		} else if (option === 'Bem-vindos') {
			routeToBemVindos();
		} else if (option === 'Mentorias') {
			routeToMentorias();
		} else if (option === 'Advocacy') {
			routeToAdvocacy();
		}
	};

	useEffect(() => {
		if (shouldNavigate) {
		
			// Navegação baseada na opção selecionada
			if (selectedOption === 'Comece por aqui') {
				routeToComecePorAqui();
			} else if (selectedOption === 'Meus Certificados') {
				routeToJornadaLabora();
			} else if (selectedOption === 'Aprenda como abrir uma MEI') {
				routeToMei();
			} else if (selectedOption === 'Especializações') {
				routeToEspecializacoes();
			} else if (selectedOption === 'Oportunidades') {
				routeToOportunidades();
			} else if (selectedOption === 'Agenda de eventos') {
				routeToAgenda();
			} else if (selectedOption === 'Roleplays') {
				routeToRoleplays();
			} else if (selectedOption === 'Ranking') {
				routeToRanking();
			} else if (selectedOption === 'Trabalho Flex') {
				routeToTrabalhoFlex();
			} else if (selectedOption === 'Disponibilidade') {
				routeToDisponibilidade();
			} else if (selectedOption === 'Baixe o app') {
				routeToAppLabora();
			} else if (selectedOption === 'Onboarding dos projetos') {
				routeToOnboarding();
			} else if (selectedOption === 'Dashboard do projeto') {
				routeToDashboard();
			} else if (selectedOption === 'Minha jornada') {
				routeToJornadasRealizadas();
			} else if (selectedOption === 'Meu financeiro') {
				routeToFinanceiro();
			} else if (selectedOption === 'Reskilling') {
				routeToReskilling();
			} else if (selectedOption === 'Processos Seletivos') {
				routeToProcSeletivos();
			} else if (selectedOption === 'Flex Labora') {
				routeToFlexLabora();
			} else if (selectedOption === 'Meu perfil') {
				routeToPerfil();
			} else if (selectedOption === 'Alterar senha') {
				routeToUpdatePassword();
			} else if (selectedOption === 'Cultura Labora') {
				routeToCulturaLabora();
			} else if (selectedOption === 'Eventos') {
				routeToEventos();
			} else if (selectedOption === 'Moedas Labora') {
				routeToMoedasLabora();
			} else if (selectedOption === 'Lab60+') {
				routeToLab60();
			} else if (selectedOption === 'Hub Labora') {
				routeToHubLabora();
			} else if (selectedOption === 'Formatura') {
				routeToFormatura();
			} else if (selectedOption === 'Bem-vindos') {
				routeToBemVindos();
			} else if (selectedOption === 'Mentorias') {
				routeToMentorias();
			} else if (selectedOption === 'Advocacy') {
				routeToAdvocacy();
			}

			setShouldNavigate(false); // Reseta o estado após a navegação
		}
	}, [routeToAdvocacy, routeToAgenda, routeToAppLabora, routeToBemVindos, routeToCulturaLabora, routeToDashboard, routeToDisponibilidade, routeToEspecializacoes, routeToEventos, routeToFinanceiro, routeToFlexLabora, routeToFormatura, routeToHubLabora, routeToJornadasRealizadas, routeToLab60, routeToMentorias, routeToMoedasLabora, routeToOnboarding, routeToOportunidades, routeToPerfil, routeToProcSeletivos, routeToRanking, routeToReskilling, routeToRoleplays, routeToTrabalhoFlex, routeToUpdatePassword, routeToComecePorAqui, routeToJornadaLabora, routeToMei, shouldNavigate, navigate, selectedOption]);

	

	return (
		<>
			<Box sx={{ display: "flex", "& > *": { mt: 2, ml: 4 } }}>
				<ButtonGroup
					orientation="vertical"
					aria-label="vertical contained button group"
					variant="contained"
					color="secondary"
					size="small"
				>

					<StyledButton color="secondary" onClick={routeToHome} key="Home">
						Plataforma Labora
					</StyledButton>

					<div className="menu-animado">

						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={submenus.reskilling ? "third" : "secondary"}
							aria-controls={submenus.reskilling ? "basic-menu" : undefined}
							aria-expanded={submenus.reskilling ? "true" : undefined}
							aria-haspopup="true"
							onClick={() => toggleSubMenu('reskilling')}>
							Reskilling {submenus.reskilling ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						{submenus.reskilling && (
							<div className="submenu open">
								{/* // <div class={`submenu ${submenus.reskilling ? 'open' : ''}`}> */}
								{/* <div class={`submenu ${open1 ? 'open' : ''}`}> */}
								<ul>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Comece por aqui' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Comece por aqui')}>Comece por aqui</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Meus Certificados' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Meus Certificados')}>Meus Certificados</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Aprenda como abrir uma MEI' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Aprenda como abrir uma MEI')}>Aprenda como abrir uma MEI</StyledButton> </li>

									{/* <li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToComecePorAqui}>Comece por aqui</StyledButton> </li>
                                <li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToJornadaLabora}>Meus Certificados</StyledButton> </li>
                                <li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToMei}>Aprenda como abrir uma MEI</StyledButton> </li> */}
								</ul>
							</div>
						)}

					</div>

					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={submenus.back2thegame ? "third" : "secondary"}
							aria-controls={submenus.back2thegame ? "basic-menu" : undefined}
							aria-expanded={submenus.back2thegame ? "true" : undefined}
							aria-haspopup="true"
							onClick={() => toggleSubMenu('back2thegame')}>
							Back 2 the Game {submenus.back2thegame ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						{submenus.back2thegame && (
							<div className="submenu open">
								<ul>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Especializações' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Especializações')}>Especializações</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Oportunidades' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Oportunidades')}>Oportunidades</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Agenda de eventos' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Agenda de eventos')}>Agenda de eventos</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Roleplays' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Roleplays')}>Roleplays / Avaliações</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Ranking' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Ranking')}>Ranking</StyledButton> </li>
								</ul>
							</div>
						)}
					</div>


					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={submenus.flexlabora ? "third" : "secondary"}
							aria-controls={submenus.flexlabora ? "basic-menu" : undefined}
							aria-expanded={submenus.flexlabora ? "true" : undefined}
							aria-haspopup="true"
							onClick={() => toggleSubMenu('flexlabora')}>
							Flex Labora {submenus.flexlabora ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						{submenus.flexlabora && (
							<div className="submenu open">
								<ul>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Trabalho Flex' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Trabalho Flex')}>Trabalho Flex</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Disponibilidade' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Disponibilidade')}>Disponibilidade</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Baixe o app' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Baixe o app')}>Baixe o app</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Onboarding dos projetos' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Onboarding dos projetos')}>Onboarding dos projetos</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Dashboard do projeto' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Dashboard do projeto')}>Dashboard do projeto</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Minha jornada' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Minha jornada')}>Minha jornada</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Meu financeiro' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Meu financeiro')}>Meu financeiro</StyledButton> </li>
								</ul>
							</div>
						)}
					</div>

					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={submenus.eunacomunidade ? "third" : "secondary"}
							aria-controls={submenus.eunacomunidade ? "basic-menu" : undefined}
							aria-expanded={submenus.eunacomunidade ? "true" : undefined}
							aria-haspopup="true"
							onClick={() => toggleSubMenu('eunacomunidade')}>
							Eu na Comunidade {submenus.eunacomunidade ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						{submenus.eunacomunidade && (
							<div className="submenu open">
								<ul>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Reskilling' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Reskilling')}>Reskilling</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Processos Seletivos' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Processos Seletivos')}>Processos Seletivos</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Flex Labora' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Flex Labora')}>Flex Labora</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Meu perfil' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Meu perfil')}>Meu perfil</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Alterar senha' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Alterar senha')}>Alterar senha</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Cultura Labora' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Cultura Labora')}>Cultura Labora</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Eventos' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Eventos')}>Eventos</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Moedas Labora' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Moedas Labora')}>Moedas Labora</StyledButton> </li>
								</ul>
							</div>
						)}
					</div>

					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={submenus.encontrosdarede ? "third" : "secondary"}
							aria-controls={submenus.encontrosdarede ? "basic-menu" : undefined}
							aria-expanded={submenus.encontrosdarede ? "true" : undefined}
							aria-haspopup="true"
							onClick={() => toggleSubMenu('encontrosdarede')}>
							Encontros da rede {submenus.encontrosdarede ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						{submenus.encontrosdarede && (
							<div className="submenu open">
								<ul>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Lab60+' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Lab60+')}>Lab60+</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Hub Labora' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Hub Labora')}>Hub Labora</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Formatura' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Formatura')}>Formatura</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Bem-vindos' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Bem-vindos')}>Bem-vindos</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Mentorias' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Mentorias')}>Mentorias</StyledButton> </li>
									<li><StyledButton sx={{ width: "100%", opacity: selectedOption === 'Advocacy' ? "0.4" : "0.7" }} color={"third"} onClick={() => handleSubmenuClickWithNavigation('Advocacy')}>Advocacy</StyledButton> </li>
								</ul>
							</div>
						)}
					</div>

					<StyledButton color="secondary" onClick={handleLogout} key="Sair">
						Sair
						{/* <LogoutTwoTone/> */}
					</StyledButton>
				</ButtonGroup>

			</Box>
		</>
	);
}

