import React, { useState } from 'react';
import { Button, TextField } from "@mui/material";
// import CloseIcon from '@mui/icons-material/Close';


const EventModal = ({ evento, onClose, onDelete, onUpdate }) => {
    const [editedEvent, setEditedEvent] = useState({ ...evento });

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setEditedEvent({ ...editedEvent, [name]: value });
    // };

    // const handleColorChange = (e) => {
    //     setEditedEvent({ ...editedEvent, color: e.target.value });
    // };

    const handleStartDateChange = (e) => {
        const startDate = new Date(e.target.value);
        if (startDate <= editedEvent.end) {
            setEditedEvent({ ...editedEvent, start: startDate });
        };
    };

    const handleEndDateChange = (e) => {
        const endDate = new Date(e.target.value);
        if (endDate >= editedEvent.start) {
            setEditedEvent({ ...editedEvent, end: endDate });
        };
    };

    const handleDelete = () => {
        onDelete(evento.id);
    };

    const handleClose = () => {
        onClose();
    };

    const handleUpdate = () => {
        onUpdate(editedEvent);
        onClose();
    };

    const adjustDate = (date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(adjustedDate.getHours() - 3);
        return adjustedDate.toISOString().slice(0, -8);
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h3>Alterar disponibilidade</h3>
                <form>
                    {/* <div className='modal-campos'>
                        <p>Título</p>
                        <TextField
                            className="alterar"
                            type="text"
                            id="title"
                            name="title"
                            variant="outlined"
                            size="small"
                            value={editedEvent.title}
                            onChange={handleInputChange}
                        />
                    </div> */}
                    <div className='modal-campos'>
                        <p>Início</p>
                        <TextField
                            className="alterar"
                            type="datetime-local"
                            id="start"
                            name="start"
                            variant="outlined"
                            size="small"
                            value={adjustDate(editedEvent.start)}
                            onChange={handleStartDateChange}
                        />

                    </div>
                    <div className='modal-campos'>
                        <p>Término</p>
                        <TextField
                            className="alterar"
                            type="datetime-local"
                            id="end"
                            name="end"
                            variant="outlined"
                            size="small"
                            value={adjustDate(editedEvent.end)}
                            onChange={handleEndDateChange}
                        />
                    </div>
                </form>
                <div className='pt20 botao-calendario pb20'>
                    <Button
                        className="botao-alterar"
                        variant="contained"
                        color="third"
                        onClick={handleDelete}
                    >
                        Apagar
                    </Button>
                    <Button
                        className="botao-alterar"
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className="botao-alterar"
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdate}
                    >
                        Salvar
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EventModal;