import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { urlResetPassword } from "../utils/Utils.js";
import BannerTermoUso from "../components/BannerTermoUso/BannerTermoUso.jsx";
import { Box, TextField } from "@mui/material";
import ModalMsg from "../components/ModalMsg/ModalMsg.jsx";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";
import Footer from "../components/Footer/Footer.jsx";

export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [reload, setReload] = useState(false);
  const recaptchaRef = useRef();
  let navigate = useNavigate();
  const routeToLogin = () => navigate("/");
  const testeLoad = () => {
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    setReload(!reload);
    const captcha = await recaptchaRef.current.executeAsync();
    if (email) {
      await axios
        .post(urlResetPassword, { email, captcha })
        .then((response) => {
          if (response.status === 200) {
            setErrMsg('Enviamos uma senha provisória para o e-mail cadastrado. Se não receber a mesma, verifique se o e-mail informado está correto ou se sua conta está cadastrada, se não, volte e clique em "Fazer Cadastro".');
            const data = { resetPass: false };
          } else {
            setErrMsg('E-mail não cadastrado, favor verificar o e-mail digitado.');
          }
        })
        .catch((error) => {
          setErrMsg('E-mail não cadastrado, favor verificar o e-mail digitado.');
          recaptchaRef.current.reset();
        });
    } else {
      setErrMsg('E-mail não foi preenchido.');
    };
    return;
  }

  function cancelar() {
    const data = { resetPass: false };
    routeToLogin();
  }

  return (
    <>
      <div>
        <BannerTermoUso></BannerTermoUso>
      </div>

      <div className="container centro">
        <h1>Redefinição de senha</h1>
        <div className="observacoes">
          <h3>Escreva seu email no campo abaixo, em instantes enviaremos um email com sua nova senha provisória.</h3>
          <li>O prazo mínimo de envio da sua senha provisória é de 2 horas.</li>
          <li>Verifique sua caixa de Spam ou Lixo Eletrônico.</li>
          <li>Verifique se você escreveu o seu email corretamente e se este email é o
            mesmo que você cadastrou anteriormente na Plataforma Labora.</li>
          <li>Em caso de outras dúvidas entre em contato com o nosso suporte via Whatsapp.</li>
        </div>
        <form className="w75" onSubmit={handleFormSubmit}>
          <div className="pb40">
            <Box
              className="box"
              sx={{ '& .MuiTextField-root': { m: 1, width: '95%' }, }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="E-mail do usuário"
                type="email"
                id="email"
                name="email"
                variant="outlined"
                size="medium"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdzTBYgAAAAAN-toGlGYaNxFWTyxYwNVuXlXvOd"
            asyncScriptOnLoad={testeLoad}
            size="invisible"
            hl="pt-BR"
            onExpired={() => { recaptchaRef.current.reset() }}
            badge="bottomleft"
          />
          <div className="pt10 botoes pb150">
            <div>
              <StyledBtn
                className="btn-banner"
                variant="contained"
                color="secondary"
                value="Cancelar"
                onClick={cancelar}
              >
                Cancelar
              </StyledBtn>
            </div>
            <div>
              <a href="https://contato.labora.tech/lp-labora-suporte" target="_blank" rel="noreferrer">
                <StyledBtn
                  className="btn-banner"
                  variant="contained"
                  color="secondary"
                  value="Suporte"
                >
                  Suporte
                </StyledBtn>
              </a>
            </div>
            <div>
              <StyledBtn
                className="btn-banner"
                variant="contained"
                type="submit"
                color="secondary"
                value="Enviar"
              >
                Enviar
              </StyledBtn>
            </div>
          </div>
        </form>
        {errMsg === 'Enviamos uma senha provisória para o e-mail cadastrado. Se não receber a mesma, verifique se o e-mail informado está correto ou se sua conta está cadastrada, se não, volte e clique em "Fazer Cadastro".' && (
          <ModalMsg msg={errMsg} modalType={"success"} />
        )}
        {errMsg === 'E-mail não foi preenchido.' && (
          <ModalMsg msg={errMsg} modalType={"success"} />
        )}
        {errMsg === 'E-mail não cadastrado, favor verificar o e-mail digitado.' && (
          <ModalMsg msg={errMsg} modalType={"success"} />
        )}
      </div>
      <Footer></Footer>
    </>
  );
}