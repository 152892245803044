import React, { createContext, useState } from 'react';

const MenuContext = createContext({
    submenus: {},
    toggleSubMenu: () => {},
    closeAllSubMenus: () => {},
    selectedOption: null,
    handleSubmenuClick: () => {},
});

export const MenuContextProvider = ({ children }) => {
    const [submenus, setSubmenus] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);

    const toggleSubMenu = (menu) => {
        setSubmenus((prevSubmenus) => {
          // Fecha todos os submenus
          const allClosed = Object.keys(prevSubmenus).reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {});
          
          // Abre ou fecha o submenu atual
          return {
            ...allClosed,
            [menu]: !prevSubmenus[menu],
          };
        });
      };

      const closeAllSubMenus = () => {
        setSubmenus((prevSubmenus) => {
            const allClosed = Object.keys(prevSubmenus).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
            return allClosed;
        });
    };


    const handleSubmenuClick = (option) => {
        setSelectedOption(option);
    };

    return (
        <MenuContext.Provider value={{ submenus, toggleSubMenu, selectedOption, handleSubmenuClick, closeAllSubMenus }}>
            {children}
        </MenuContext.Provider>
    );
};

export default MenuContext;
