import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
// import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { MenuItem, Checkbox, Box } from "@mui/material";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import { updateUser, readUser } from "../services/api";
import ModalMsg from "../components/ModalMsg/ModalMsg.jsx";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import PolPrivacidade from "../components/PolPrivacidade/PolPrivacidade";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";
import {
  meses,
  estadoss,
  generos,
  racas,
  lgbts,
  transs,
  deficientes,
  formacoes,
} from "../components/VariaveisOpcoes/VariaveisOpcoes.jsx";

import {
  normalizeCep,
  normalizePhone,
  normalizeCpf,
} from "../components/Mascaras/Mascaras.jsx";


function ValidarCpf(cpf) {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não for dígito

  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false; // Verifica se o CPF tem 11 dígitos ou se todos os dígitos são iguais
  }

  let soma = 0;
  let resto;

  // Validação do primeiro dígito verificador
  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;

  // Validação do segundo dígito verificador
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
}

export default function Perfil() {
  const token = localStorage.getItem("token");
  const [errMsg, setErrMsg] = useState("");
  const [userDataResponse, setUserDataResponse] = useState();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const routeToHome = () => navigate("/Home");
  const { register, control, handleSubmit, formState: { errors } } = useForm({
    // const { register, control, handleSubmit, formState: { errors }, trigger } = useForm({
    mode: "onChange", // Validação ao digitar
  });

  const [cpfValido, setCpfValido] = useState(false);
  const [cepValido, setCepValido] = useState(false);
  const [phoneValido, setPhoneValido] = useState(false);

  const cpfRef = useRef(null);
  const cepRef = useRef(null);
  const phoneRef = useRef(null);

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("handle event");
  };

  const setFormData = (formData, data) => {
    const keys = Object.keys(data);
    console.log("keys:", keys);
    for (let i in keys) {
      if (typeof data[keys[i]] === "object") {
        for (let j in data[keys[i]]) {
          // console.log(`=> ${keys[i]}[${j}]: ${data[keys[i]][j]}`);
          if (typeof data[keys[i]][j] === "object") {
            formData.append(
              `${keys[i]}[${j}]`,
              JSON.stringify(data[keys[i]][j])
            );
          } else {
            formData.append(`${keys[i]}[${j}]`, data[keys[i]][j]);
          }
        }
      } else {
        //console.log(`${i}
        //console.log(`${i} || ${keys[i]}: ${data[keys[i]]}`);
        formData.append(`${keys[i]}`, data[keys[i]]);
      }
    }
  };

  const [image, setImage] = useState('');
  const [endImg, setEndImg] = useState("");

  const onSubmit = (data) => {
    console.log("DATA: ", data);

    const dadosFormatados = {
      cadastro1: {
        address: data.cadastro1.address,
        city: data.cadastro1.city,
        cep: data.cadastro1.cep,
        phone: data.cadastro1.phone,
        state: data.cadastro1.state,
      },
      cadastro2: {
        birthday_day: data.cadastro2.birthday_day,
        birthday_month: data.cadastro2.birthday_month,
        birthday_year: data.cadastro2.birthday_year,
        deficiency: data.cadastro2.deficiency,
        ethnic_group: data.cadastro2.ethnic_group,
        gender: data.cadastro2.gender,
        sexual_orientation: data.cadastro2.sexual_orientation,
        transsexual: data.cadastro2.transsexual,
      },
      cadastro3: {
        cpf: data.cadastro3.cpf,
        formacao: data.cadastro3.formacao,
      },
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    };

    let formData = new FormData();
    formData.append('filetoupload', image);
    console.log("formdata: ", formData);
    const keys = Object.keys(data);
    console.log("Keys: ", keys);

    setFormData(formData, dadosFormatados);

    for (let i of formData.entries()) {
      console.log("FormData: ", i[0] + ", " + i[1]);
    }

    async function updateDataUser() {
      await updateUser(token, formData).then((response) => {
        console.log("response do perfil", response);
        if (response.status >= 200 && response.status < 400) {
          setErrMsg("Atualização realizada com sucesso!");
        }
      });
    }
    updateDataUser();
  };

  useEffect(() => {
    async function fetchData() {
      const dataResponse = await readUser(token);
      setLoaded(true);
      setUserDataResponse(dataResponse);
      setEndImg(dataResponse.data.photo);
      // Validação inicial dos campos CPF, CEP e telefone
      const cpfIsValid = ValidarCpf(dataResponse.data.cadastro3.cpf);
      const phoneIsValid = /^\(\d{2}\) \d{4,5}-\d{4}$/.test(dataResponse.data.cadastro1.phone);
      const cepIsValid = /^[0-9]{5}-[0-9]{3}$/.test(dataResponse.data.cadastro1.cep);

      setCpfValido(cpfIsValid);
      setPhoneValido(phoneIsValid);
      setCepValido(cepIsValid);

    }
    fetchData();
  }, [token]);

  // const formatCPF = (value) => {
  //   // Remove todos os caracteres não numéricos
  //   const numbers = value.replace(/\D/g, '');

  //   // Formata o CPF no padrão XXX.XXX.XXX-XX
  //   return numbers
  //     .replace(/^(\d{3})(\d+)/, '$1.$2')
  //     .replace(/\.(\d{3})(\d+)/, '.$1.$2')
  //     .replace(/\.(\d{3})(\d+)/, '.$1-$2')
  //     .substring(0, 14);
  // };

  // const [cpf, setCPF] = useState('');

  // const handleChangeCpf = (event) => {
  //   const { value } = event.target;
  //   setCPF(formatCPF(value));
  // };

  if (!loaded) return <div>Carregando Perfil...</div>;
  else {
    // const handleBlur = async (fieldName, fieldRef) => {
    //   const isFieldValid = await trigger(fieldName);
    //   const fieldValue = fieldRef.current.value;

    //   if (!fieldValue) {
    //     fieldRef.current.focus(); // Mantém o foco se o campo estiver vazio (obrigatório)
    //   } else if (!isFieldValid) {
    //     fieldRef.current.focus(); // Mantém o foco se o campo for inválido (formato)
    //   }
    // };

    return (
      <>
        {/* <div className="container-header">
          <header className="App-header">
            <Banner title="Meu Perfil" description="Perfil Labora Tech." />
          </header>
        </div> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-perfil">
            {/* <div className="container-menu">
              <MenuPerfil></MenuPerfil>
            </div> */}
            <div className="container-box pt10">
              <Box sx={{ mb: 2, border: "1px solid #d1d2d2", borderRadius: 1 }} className="header-talento">
                <Box className="label">
                  <label className="label-talento" htmlFor="Foto">Selecione sua foto de até 5MB e em formato quadrado:</label>
                </Box>
                <Box className="box-talento">
                  <Box className="box-imagem">
                    {image ? <img src={URL.createObjectURL(image)} alt="FOTO" className="img-talento" /> :
                      <img src={endImg} alt="Imagem 1" className="img-talento" />}
                  </Box>
                  <Box className="box-imagem">
                    <input
                      type="file"
                      accept="image/*"
                      name="photo"
                      id="photo"
                      onChange={e => setImage(e.target.files[0])}
                      disabled={!cpfValido || !cepValido || !phoneValido}
                    />
                  </Box>
                </Box>
              </Box>
              <TextField
                label="E-mail"
                className="textfield-2 fl"
                variant="outlined"
                size="small"
                value={userDataResponse.data.email}
                readOnly={true}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("email")}
              />

              <Controller
                name="cadastro3.cpf"
                control={control}
                rules={{
                  required: "CPF é obrigatório",
                  pattern: {
                    value: /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
                    message: "Somente números",
                  },
                  validate: (value) => {
                    const normalizedValue = normalizeCpf(value);
                    const isValid = ValidarCpf(normalizedValue);
                    setCpfValido(isValid);
                    return isValid || "CPF inválido";
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputRef={(ref) => {
                      field.ref(ref);
                      cpfRef.current = ref; // Atribui a referência do campo de CPF
                    }}
                    label="CPF"
                    className="textfield-2 fr"
                    variant="outlined"
                    size="small"
                    defaultValue={normalizeCpf(userDataResponse.data.cadastro3.cpf)}
                    placeholder="999.999.999-99"
                    required
                    disabled={!phoneValido || !cepValido} // Desabilita se Telefone ou CEP forem inválidos
                    error={!!errors.cadastro3?.cpf} // Destaca o campo em vermelho se houver erro
                    helperText={errors.cadastro3?.cpf?.message} // Exibe a mensagem de erro em vermelho
                    // readOnly={true}
                    {...register("cadastro3.cpf")}
                    onChange={(e) => {
                      const value = e.target.value;
                      const normalizedValue = normalizeCpf(value);
                      field.onChange(normalizedValue);
                      const isValid = ValidarCpf(normalizedValue);
                      setCpfValido(isValid); // Atualiza o estado de validade do CPF
                    }}
                    onBlur={() => {
                      const isValid = ValidarCpf(cpfRef.current.value);
                      setCpfValido(isValid);
                      if (!isValid) {
                        cpfRef.current.focus(); // Mantém o foco no campo CPF se for inválido
                      }
                    }}
                  />
                )}
              />

              <TextField
                label="Nome"
                className="textfield-3 fl"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.first_name}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("first_name")}
              />
              <TextField
                label="Sobrenome"
                className="textfield-3"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.last_name}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("last_name")}
              />

              <Controller
                name="cadastro1.phone"
                control={control}
                rules={{
                  required: "Telefone é obrigatório",
                  pattern: {
                    value: /^\(\d{2}\)\s\d{5}-\d{4}$/, // Padrão de telefone
                    message: "Somente números DD999999999",
                  },
                  validate: (value) => {
                    const isValid = /^\(\d{2}\)\s\d{5}-\d{4}$/.test(value); // Validação do formato do telefone
                    setPhoneValido(isValid); // Atualiza o estado de validade do telefone
                    return isValid || "Telefone inválido";
                  },
                }}

                render={({ field }) => (
                  <TextField
                    {...field}
                    inputRef={(ref) => {
                      field.ref(ref);
                      phoneRef.current = ref; // Atribui a referência do campo de Phone
                    }}
                    label="Telefone"
                    className="textfield-3 fr"
                    variant="outlined"
                    size="small"
                    defaultValue={normalizePhone(userDataResponse.data.cadastro1.phone)}
                    placeholder="(DD) 99999-9999"
                    required
                    disabled={!cpfValido || !cepValido} // Desabilita se CPF ou CEP forem inválidos
                    error={!!errors.cadastro1?.phone} // Destaca o campo em vermelho se houver erro
                    helperText={errors.cadastro1?.phone?.message} // Exibe a mensagem de erro em vermelho
                    {...register("cadastro1.phone")}
                    onChange={(e) => {
                      const value = e.target.value;
                      const normalizedValue = normalizePhone(value); // Normaliza o valor do telefone
                      field.onChange(normalizedValue); // Atualiza o campo com o valor normalizado
                      const isValid = /^\(\d{2}\)\s\d{5}-\d{4}$/.test(normalizedValue); // Verifica a validade do telefone
                      setPhoneValido(isValid); // Atualiza o estado de validade do telefone
                    }}
                    onBlur={() => {
                      const isValid = /^\(\d{2}\)\s\d{5}-\d{4}$/.test(phoneRef.current.value); // Valida o telefone ao perder o foco
                      setPhoneValido(isValid);
                      if (!isValid) {
                        phoneRef.current.focus(); // Mantém o foco no campo de telefone se for inválido
                      }
                    }}
                  />
                )}
              />

              <TextField
                label="Dia de nascimento"
                className="textfield-3 fl"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro2.birthday_day}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro2.birthday_day")}
              />
              <TextField
                label="Mês de nascimento"
                className="textfield-3"
                select
                onChange={handleChange}
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro2.birthday_month}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro2.birthday_month")}
              >
                {meses.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Ano de nascimento"
                className="textfield-3 fr"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro2.birthday_year}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro2.birthday_year")}
              />
              <TextField
                label="Endereço"
                className="textfield-1"
                variant="outlined"
                size="small"
                required
                defaultValue={userDataResponse.data.cadastro1.address}
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos              
                {...register("cadastro1.address")}
              />
              <TextField
                label="Cidade"
                className="textfield-3 fl"
                variant="outlined"
                size="small"
                defaultValue={userDataResponse.data.cadastro1.city}
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro1.city")}
              />
              <TextField
                label="Estado"
                className="textfield-3"
                select
                defaultValue={userDataResponse.data.cadastro1.state}
                onChange={handleChange}
                variant="outlined"
                size="small"
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro1.state")}
              >
                {estadoss.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>


              <Controller
                name="cadastro1.cep"
                control={control}
                rules={{
                  required: "CEP é obrigatório",
                  pattern: {
                    value: /^[0-9]{5}-[0-9]{3}$/, // Padrão de CEP
                    message: "Somente números 99999999",
                  },
                  validate: value => {
                    const isValid = /^[0-9]{5}-[0-9]{3}$/.test(value); // Validação do formato do CEP
                    setCepValido(isValid); // Atualiza o estado de validade do CEP
                    return isValid || "CEP inválido";
                  },
                }}

                render={({ field }) => (
                  <TextField
                    {...field}
                    inputRef={(ref) => {
                      field.ref(ref);
                      cepRef.current = ref; // Atribui a referência do campo de CEP
                    }}
                    label="CEP"
                    className="textfield-3 fr"
                    variant="outlined"
                    size="small"
                    defaultValue={normalizeCep(userDataResponse.data.cadastro1.cep)}
                    placeholder="99999-999"
                    required
                    disabled={!cpfValido || !phoneValido} // Desabilita se CPF ou Telefone forem inválidos
                    error={!!errors.cadastro1?.cep}
                    helperText={errors.cadastro1?.cep?.message}
                    {...register("cadastro1.cep")}

                    onChange={(e) => {
                      const value = e.target.value;
                      const normalizedValue = normalizeCep(value); // Normaliza o valor do CEP
                      field.onChange(normalizedValue); // Atualiza o campo com o valor normalizado
                      const isValid = /^[0-9]{5}-[0-9]{3}$/.test(normalizedValue); // Verifica a validade do CEP
                      setCepValido(isValid); // Atualiza o estado de validade do CEP
                    }}
                    onBlur={() => {
                      const isValid = /^[0-9]{5}-[0-9]{3}$/.test(cepRef.current.value); // Valida o CEP ao perder o foco
                      setCepValido(isValid);
                      if (!isValid) {
                        cepRef.current.focus(); // Mantém o foco no campo de CEP se for inválido
                      }
                    }}
                  />
                )}
              />

              <TextField
                label="Etnia/raça autodeclarada"
                className="textfield-2a fl"
                select
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                defaultValue={userDataResponse.data.cadastro2.ethnic_group}
                {...register("cadastro2.ethnic_group")}
              >
                {racas.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Gênero autodeclarado"
                className="textfield-2a fr"
                select
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                defaultValue={userDataResponse.data.cadastro2.gender}
                {...register("cadastro2.gender")}
              >
                {generos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Você é uma pessoa LGBTQIA+?"
                className="textfield-2a fl"
                select
                defaultValue={
                  userDataResponse.data.cadastro2.sexual_orientation
                }
                onChange={handleChange}
                variant="outlined"
                size="small"
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                // required
                {...register("cadastro2.sexual_orientation")}
              >
                {lgbts.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Você possui alguma deficiência?"
                className="textfield-2a fr"
                select
                defaultValue={userDataResponse.data.cadastro2.deficiency}
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro2.deficiency")}
              >
                {deficientes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Identifica-se como pessoa trans?"
                className="textfield-2a fl"
                select
                defaultValue={userDataResponse.data.cadastro2.transsexual}
                onChange={handleChange}
                variant="outlined"
                size="small"
                // required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro2.transsexual")}
              >
                {transs.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Formação acadêmica"
                className="textfield-2a fr"
                select
                defaultValue={userDataResponse.data.cadastro3.formacao}
                onChange={handleChange}
                variant="outlined"
                size="small"
                required
                disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                {...register("cadastro3.formacao")}
              >
                {formacoes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* <div className="pt10 pb20 botao-perfil"> */}
              <div className="centro pt10">
                <PolPrivacidade></PolPrivacidade>
                <div className="pt10 pb10">
                  <p className="termo-uso">
                    Leia e aceite a política de privacidade para atualizar o
                    cadastro
                  </p>
                  <Checkbox
                    className="checkbox"
                    size="small"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={!cpfValido || !cepValido || !phoneValido}
                  />
                  <strong className="strong">
                    Concordo com a política de privacidade Labora
                  </strong>
                </div>
                <div className="pt10 pb20 botao-perfil">
                  <StyledBtn
                    type="submit"
                    className="btn-banner"
                    variant="contained"
                    color="third"
                    disabled={!checked || !cpfValido || !cepValido || !phoneValido}
                  >
                    Salvar
                  </StyledBtn>
                  <StyledBtn
                    // className="btn-banner"
                    variant="contained"
                    color="secondary"
                    onClick={routeToHome}
                  >
                    Cancelar
                  </StyledBtn>
                </div>
              </div>
            </div>
            {/* {console.log("erro mensagem antes do modal", errMsg)} */}
            {errMsg === "Atualização realizada com sucesso!" && (
              <ModalMsg msg={errMsg} modalType={"editedToHome"} />
            )}
          </div>
        </form>
        {/* <Footer></Footer> */}
      </>
    );
  }
}