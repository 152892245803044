import React from 'react';
import "./Calendario.css";
import { Views } from 'react-big-calendar';

const CustomToolbar = (props) => {
    const { onView, onNavigate, view, views, label } = props;

    const handleViewChange = (event) => {
        onView(event.target.value);
    };

    return (
        <>
            <div className="rbc-toolbar">
                {/* <button onClick={() => onNavigate('TODAY')}>Hoje</button>
            <button onClick={() => onNavigate('WEEK')}>Semana</button> */}
                <button onClick={() => onNavigate('PREV')}>{"<<"}</button>
                <span className="rbc-toolbar-label">{label}</span>
                <button onClick={() => onNavigate('NEXT')}>{">>"}</button>
                <select
                    // className="botao-select"
                    value={view}
                    onChange={handleViewChange}
                >
                    {views.includes(Views.MONTH) && (
                        <option value={Views.MONTH}>Mês</option>  // Rótulo personalizado
                    )}
                    {views.includes(Views.WEEK) && (
                        <option value={Views.WEEK}>Semana</option> // Rótulo personalizado
                    )}
                    {views.includes(Views.DAY) && (
                        <option value={Views.DAY}>Dia</option> // Rótulo personalizado
                    )}
                    {views.includes(Views.AGENDA) && (
                        <option value={Views.AGENDA}>Agenda</option> // Rótulo personalizado
                    )}
                    {/* {views.map((v) => (
                    <option key={v} value={v}>
                        {v.charAt(0).toUpperCase() + v.slice(1)}
                    </option>
                ))} */}
                </select>
                {/* <button onClick={() => onNavigate("agenda")}>Agenda</button>
            <button onClick={() => onView(views.month)}>Mês</button> */}
            </div>
            {/* <div>Feriados: </div> */}
        </>
    );
};

export default CustomToolbar;