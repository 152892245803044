import React, { useState, createContext, useEffect } from 'react';
import { readUser } from '../services/api';

export const UserContext = createContext(); 

export const UserProvider = ({children}) => {
  // console.log("entrei no UserProvider");
  const [userData, setUserData] = useState([null]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  // console.log("token1:", token);
  
useEffect(() => {
  const tokenRecovered = localStorage.getItem('token');
  // console.log("token dentro do useEffect capturado do localtorage: ", localStorage.getItem('token'));
  // console.log("token2:", tokenRecovered);
  if (tokenRecovered) {
    setToken(tokenRecovered);
  }
  // console.log("token2.1:", tokenRecovered); 
    // console.log("tipo do token:", typeof token);
  setLoading(false);
  }, []);
  // console.log("token3:", token);

  const readUserData = async (token) => {
    // console.log("Entrei no readUserData");
    // console.log("readUserData-useUpdate token:", token);
    const response = await readUser(token); 
    // console.log("readUserData response:", response);
    setUserData(response);
    // console.log('userData-userupdate-data: ', response.data.id);
    // console.log('UserData: ', userData);

    return response;
  };
  // console.log('userData-userupdate-fora', response);
  
 
return (
    <UserContext.Provider value={{ userData, readUserData, token, loading }}>
      {children}
    </UserContext.Provider>
  )
};