// Mostra o status das certificações Labora e de parceiros
import React, { useEffect, useState } from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import { readJornadaLabora } from "../services/api";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import BarraProgresso from "../components/BarraProgresso/BarraProgresso.jsx";
import CertificadoFoto from "../components/CertificadoFoto/CertificadoFoto.jsx";
import "moment/locale/pt-br";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { readCertificados } from "../services/api";

// Modelo da barra de progresso para as certificações de parceiros Labora
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 600,
  borderRadius: 15,
  [theme.breakpoints.down("sm")]: {
    height: 10,
    width: 210,
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: 13,
    width: 390,
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: 15,
    width: 450,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: theme.palette.mode === "light" ? "#04b304" : "#f7a600",
  },
}));

// Modelo da linha que mostra o início e fim de cursos de parceiros Labora
const Paragrafo = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  color: "text.secondary",
  fontFamily: "Quicksand",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.45rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1rem",
  },
}));

export default function JornadaLabora() {
  const token = localStorage.getItem("token");
  const [loaded, setLoaded] = useState(false);
  const [loaded1, setLoaded1] = useState(false);
  const moment = require("moment");
  const [ordemData, setOrdemData] = useState("");
  const [existeCertificacao, setExisteCertificacao] = useState(true);
  const [certificado, setCertificado] = useState("");
  const [existeCertificado, setExisteCertificado] = useState(true);
  const [matriz, setMatriz] = useState("");

  // Cria um array com as certificações Labora a serem iniciadas
  useEffect(() => {
    const matrizCursos = [
      {
        id_curso: 772853,
        nome_curso: "Bem-vind@ à sua 2ª Carreira",
        inicio_curso: "Certificação não iniciada !",
        fim_curso: "0",
        porcento_curso: "0",
      },
      {
        id_curso: 848187,
        nome_curso: "Especialista em Experiência do Cliente",
        inicio_curso: "Certificação não iniciada !",
        fim_curso: "0",
        porcento_curso: "0",
      },
      {
        id_curso: 1001238,
        nome_curso: "Especialista em Produtos Digitais",
        inicio_curso: "Certificação não iniciada !",
        fim_curso: "0",
        porcento_curso: "0",
      },
      {
        id_curso: 2215145,
        nome_curso: "Especialista em Inside Sales (Beta)",
        inicio_curso: "Certificação não iniciada !",
        fim_curso: "0",
        porcento_curso: "0",
      },
      {
        id_curso: 1620534,
        nome_curso: "Inclusão da Diversidade Geracional",
        inicio_curso: "Certificação não iniciada !",
        fim_curso: "0",
        porcento_curso: "0",
      },
      {
        id_curso: 2416561,
        nome_curso: "Especialista em Inside Sales - BDR e SDR",
        inicio_curso: "Certificação não iniciada !",
        fim_curso: "0",
        porcento_curso: "0",
      },
    ];
    setMatriz(matrizCursos);
  }, []);

  useEffect(() => {
    // Puxa as certificações Labora concluídas ou em andamento
    async function fetchJornada() {
      const jornadaResponse = await readJornadaLabora(token);
      setLoaded(true);
      const journey = jornadaResponse.data.rows.map((selecao, index) => {
        return {
          key: index,
          id_curso: selecao.course_id,
          nome_curso: selecao.course_name,
          inicio_curso: moment(selecao.started_at).locale("pt-br").format("LL"),
          fim_curso: moment(selecao.completed_at).locale("pt-br").format("LL"),
          porcento_curso: selecao.percentage_completed,
          tempo_curso: new Date(selecao.started_at).getTime(),
        };
      });
      if (journey.length === 0) {
        setExisteCertificacao(false);
      } else {
        let result = [...journey].sort((a, b) =>
          a.tempo_curso > b.tempo_curso ? 1 : -1
        );
        setOrdemData(result);
      }
    }
    fetchJornada();

    // Puxa certificações de parceiros Labora e outras certificações/diplomas/cursos
    async function fetchCertificados() {
      const certificadosResponse = await readCertificados(token);
      setLoaded1(true);
      if (certificadosResponse.data.rows.length !== 0) {
        const select = certificadosResponse.data.rows.map((selecao, index) => {
          return {
            id: index,
            instituicao: selecao.instituicao,
            curso: selecao.curso,
            id_curso: selecao.instituicao,
            inicio: moment(selecao.data_inicio).locale("pt-br").format("LL"),
            fim: moment(selecao.data_conclusao).locale("pt-br").format("LL"),
            tempo: new Date(selecao.data_inicio).getTime(),
          };
        });
        let resultado = [...select].sort((a, b) =>
          a.tempo > b.tempo ? 1 : -1
        );
        setCertificado(resultado);
      } else {
        setExisteCertificado(false);
      }
    }
    fetchCertificados();
  }, [token, moment]);

  // Cria array com certificações Labora concluídas/em andamento e a serem iniciadas
  useEffect(() => {
    let newData = [];
    let cont = 0;
    if (matriz.length !== ordemData.length) {
      for (let i = 0; i < matriz.length; i++) {
        for (let j = 0; j < ordemData.length; j++) {
          if (matriz[i].id_curso === ordemData[j].id_curso) {
            newData.push(ordemData[j]);
            cont++;
          }
        }
        if (cont === 0) {
          newData.push(matriz[i]);
        } else {
          cont = 0;
        }
      }
    } else {
      newData = ordemData;
    }
    setOrdemData(newData);
  }, [loaded, matriz, ordemData]);

  if (!loaded || !loaded1) {
    return <div>Carregando JornadaLabora...</div>;
  } else {
    return (
      <>
        {/* <div className="container-header"> */}
        {/*   <header className="app-header">
            <Banner title="Certificações" description="Jornada Labora Tech." />
          </header>
        </div> */}
        <div className="container-perfil pb40">
          {/*<div className="container-menu">
            <MenuPerfil></MenuPerfil>
          </div> */}
          <div className="box-jornada">
            {/*Mostra as certificações Labora */}
            <div className="jornada-header">
              {/* {existeCertificacao && <h1>Certificações Labora</h1>} */}
              {!existeCertificacao && (
                <div>
                  <h2>Não possui certificações pela Labora !</h2>
                  <h2>Certificações disponíveis:</h2>
                </div>
              )}
            </div>
            {existeCertificacao &&
              ordemData.map((jornada, index) => (
                <div className="jornada" key={index}>
                  <CertificadoFoto foto={jornada} />
                  <div className="jornada-cursos">
                    <h4>{jornada.nome_curso}</h4>
                    <BarraProgresso barra={jornada} />
                  </div>
                </div>
              ))}
            {!existeCertificacao &&
              matriz.map((jornada, index) => (
                <div className="jornada" key={index}>
                  <CertificadoFoto foto={jornada} />
                  <div className="jornada-cursos">
                    <h4>{jornada.nome_curso}</h4>
                    <BarraProgresso barra={jornada} />
                  </div>
                </div>
              ))}
            {/* Mostra as certificações de parceiros Labora */}
            {/* <div className="jornada-header">
              {existeCertificado && <h1>Certificações de parceiros Labora</h1>}
              {!existeCertificado && (
                <h1>Não possui certificados de parceiros Labora !</h1>
              )}
            </div> */}
            {/* {
              existeCertificado &&
              certificado.map((certifica, index) => (
                <div className="jornada" key={index}>
                  <CertificadoFoto foto={certifica} />
                  <div className="jornada-cursos">
                    <h4>
                      {certifica.instituicao}: {certifica.curso}
                    </h4>
                    <Box sx={{ display: "flexBox", alignItems: "center" }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <div>
                          <Paragrafo>
                            <strong>Início: </strong>
                            {certifica.inicio}
                            {"  -  "}
                            <strong>Término: </strong>
                            {certifica.fim}
                          </Paragrafo>
                        </div>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ flexGrow: 1 }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value="100"
                          />
                        </Box>
                        <Box sx={{ minWidth: 1 }}>
                          <Paragrafo marginLeft="0.5rem">100%</Paragrafo>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))
              // Mostra outras certificações/diplomas/cursos
              // <div className="procsel-nome" key={index}>
              //     <Box sx={{ display: 'flexBox' }}>
              //         <Box sx={{ flexGrow: 1, mt: -0.5, pl: 1, pr: 2 }}>
              //             <p className="certificado-p"><strong>INSTITUIÇÃO: </strong>{certifica.instituicao}</p>
              //             <p className="certificado-p"><strong>CURSO: </strong>{certifica.curso} - <strong>TIPO: </strong>{certifica.tipo}</p>
              //             <p className="certificado-p"><strong>INÍCIO: </strong>{certifica.inicio} - <strong>CONCLUSÃO: </strong>{certifica.fim}</p>
              //         </Box>
              //     </Box>
              // </div>
              // ))
            } */}
           </div>
           </div>
        {/* </div> */}
        {/*<Footer></Footer> */}
      </>
    );
  }
}
