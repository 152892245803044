import React, { useEffect, useState } from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import SocialMedias from "../components/SocialMedias/SocialMedias.jsx";
import "./PagesStyles.css";
import { readJornadasRealizadas } from "../services/api.js";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import "moment/locale/pt-br";
import { MenuItem, TextField } from "@mui/material";

const meses = [
  { value: "01", label: "Janeiro" },
  { value: "02", label: "Fevereiro" },
  { value: "03", label: "Março" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Maio" },
  { value: "06", label: "Junho" },
  { value: "07", label: "Julho" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Setembro" },
  { value: "10", label: "Outubro" },
  { value: "11", label: "Novembro" },
  { value: "12", label: "Dezembro" },
];

const anos = [
  // { value: "2019", label: "2019" },
  // { value: "2020", label: "2020" },
  // { value: "2021", label: "2021" },
  // { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
];

export default function JornadasRealizadas() {
  const token = localStorage.getItem("token");
  const [loaded, setLoaded] = useState(false);
  const moment = require("moment");
  const [cliente, setCliente] = useState("");
  const [existeEmpresa, setExisteEmpresa] = useState(true);
  const [mes, setMes] = useState("");
  const [ano, setAno] = useState("");

  const handleChange = (event) => {
    setMes(event.target.value);
  };
  const handleChange1 = (event) => {
    setAno(event.target.value);
  };

  console.log(mes, ano);

  useEffect(() => {
    async function fetchJornadasRealizadas() {
      const jornadasRealizadasResponse = await readJornadasRealizadas(token);
      setLoaded(true);
      console.log("JornadasRealizadasResponse: ", jornadasRealizadasResponse);
      const select = jornadasRealizadasResponse.data.rows.map(
        (selecao, index) => {
          if (selecao.desembarqueId !== null && selecao.embarqueId !== null) {
            return {
              id: index,
              empresa: selecao.embarqueId.agency.companyId.name,
              posicao: selecao.embarqueId.agency.companyId.positionTitle,
              embarque: moment(selecao.embarqueId.createdAt)
                .locale("pt-br")
                .format("L - LT"),
              desembarque: moment(selecao.desembarqueId.createdAt).format(
                "L - LT"
              ),
              tempo: new Date(selecao.createdAt).getTime(),
            };
          } else {
            return {
              id: index,
              empresa: "nulo",
              posicao: "nulo",
              embarque: "nulo",
              desembarque: "nulo",
              tempo: "nulo",
            };
          }
        }
      );
      console.log("Select: ", select);

      if (select.length !== 0) {
        let resultado = [...select].sort((a, b) =>
          a.tempo > b.tempo ? -1 : 1
        );
        setCliente(resultado);
        console.log("Resultado:", resultado);
      } else {
        setExisteEmpresa(false);
      }
    }
    fetchJornadasRealizadas();
  }, [token, moment]);

  const selec = [];
  for (let i = 0; i < cliente.length; i++) {
    if (
      cliente[i].empresa !== "nulo" &&
      mes === cliente[i].embarque.substr(3, 2) &&
      ano === cliente[i].embarque.substr(6, 4)
    ) {
      selec[i] = cliente[i];
    }
  }
  console.log("Selec:", selec);

  if (!loaded) {
    return <div>Carregando Jornadas Realizadas...</div>;
  } else {
    return (
      <>
        {/* <div className="container-header">
          <header className="app-header">
            <Banner
              title="Minha jornada"
              description="Empresas Labora Tech."
            />
          </header>
        </div>*/}
        <div className="container-perfil">
         {/* <div className="container-menu">
            <MenuPerfil></MenuPerfil>
          </div>*/}
          <div className="box-jornada">
            {/* <br />  */}
            {/* <br /> */}
            <div className="box-jornada-h2">
              {existeEmpresa && <h2>Selecione mês e ano da jornada</h2>}
            </div>
            <div>
              {existeEmpresa && (
                <div className="box">
                  <TextField
                    className="textfield-2 fl"
                    select
                    label="Mês"
                    id="mes"
                    defaultValue={""}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    color="warning"
                  >
                    {meses.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className="textfield-2 fr"
                    textALign="center"
                    select
                    label="Ano"
                    id="ano"
                    defaultValue={""}
                    onChange={handleChange1}
                    variant="outlined"
                    size="small"
                    color="warning"
                  >
                    {anos.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
            </div>
            <div>
              {existeEmpresa && (
                <div className="jornada-grid">
                  <div class="jornada-grid-box">
                    <h1>EMPRESA</h1>
                  </div>
                  <div class="jornada-grid-box">
                    <h1>POSIÇÃO</h1>
                  </div>
                  <div class="jornada-grid-box">
                    <h1>EMBARQUE</h1>
                  </div>
                  <div class="jornada-grid-box">
                    <h1>DESEMBARQUE</h1>
                  </div>
                </div>
              )}
              {!existeEmpresa && (
                <h3>Não realizou trabalho flex em empresas pela Labora !</h3>
              )}
            </div>
            {existeEmpresa &&
              selec.map((empresa, index) => (
                <div className="jornada-grid" key={index}>
                  <div class="jornada-grid-box">
                    <p>{empresa.empresa}</p>
                  </div>
                  <div class="jornada-grid-box">
                    <p>{empresa.posicao}</p>
                  </div>
                  <div class="jornada-grid-box">
                    <p>{empresa.embarque}</p>
                  </div>
                  <div class="jornada-grid-box">
                    <p>{empresa.desembarque}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* <Footer></Footer> */}
      </>
    );
  }
}
