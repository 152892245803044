import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import Layout from "../Layout.jsx";

import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import imagem from "../assets/images/colmeia.png";

export default function Home() {
	return (
		<>
			{/* <Layout title="Plataforma Labora" description="Página de talentos Labora Tech."> */}

			{/* <header>
				<Banner title="Plataforma Labora" description="Página de talentos Labora Tech." />
			</header>

			<div className="container-perfil pb40">
				<div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box"> */}
					<h1 className="reinvencao_explicacao pt60">1ª plataforma de tecnologia para inclusão produtiva e trabalho flex do Brasil.</h1>
					<p className="reinvencao_explicacao">Na Labora, acreditamos no poder da diversidade e da inclusão. Nossa plataforma é
						feita para conectar talentos diversos com as melhores oportunidades do mercado, promovendo requalificação e
						desenvolvimento contínuo.</p>
					<h2 className="reinvencao_explicacao">Aqui, você encontrará:</h2>
					<div className="reinvencao_explicacao">
						<li><strong>Comunidade Diversa:</strong> Valorizamos todas as diversidades, incluindo seniores,
							gênero, raça, LGBTQIAP+, jovens de periferia e pessoas com deficiência.</li><br />
						<li><strong>Certificações Gratuitas:</strong> Oferecemos cursos online para te ajudar a entrar
							ou voltar ao mercado de trabalho.</li><br />
						<li><strong>Tecnologia Avançada:</strong> Identificamos as melhores oportunidades para você,
							sem viés inconsciente.</li><br />
						<li><strong>Flexibilidade:</strong> Oportunidades de trabalho flexível para melhorar sua qualidade
							de vida.</li><br></br>
					</div>
					<h2 className="reinvencao_explicacao">Você poderá navegar e se reinventar por:</h2>
					<img className="imagem_colmeia pb20" src={imagem} alt="Colmeia" />
					<h2 className="reinvencao_explicacao pt20">Comece pelo menu Reskilling e seja muito bem-vindo.</h2>
					{/* </ul> */}
					{/* <h1 class="referral" className="header-facebook">Notícias da Labora</h1>
					<link
						href="https://assets.juicer.io/embed.css"
						media="all"
						rel="stylesheet"
						type="text/css"
					/>
					<ul
						className="juicer-feed"
						data-feed-id="labora-6f25dc31-69f5-4328-ae2b-ebe4043eb44a"
						// data-pages="2"
						data-per="4"
						data-columns="4"
						data-truncate="150"
						data-gutter="50"
						data-origin="embed-code">
					</ul> */}
				{/* </div>
			</div>
			<Footer /> */}
		{/* </Layout> */}
		</>
	);
}
