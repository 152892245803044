import React from 'react';


import Rotas from "./routes/index.js";
import { MenuContextProvider } from './components/MenuPerfil/MenuContext.jsx';

function App() {
  
  return (
    <MenuContextProvider>
      <div>
        <Rotas />
      </div>
    </MenuContextProvider>
  );
}

export default App;
