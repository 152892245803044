// Layout.js
import React from "react";
import Banner from "./components/Banner/Banner.jsx";
import Footer from "./components/Footer/Footer.jsx";
import MenuPerfil from "./components/MenuPerfil/MenuPerfil.jsx";
// import "./Layout.css";

export default function Layout({ title, description, children }) {
    return (
        <>
            <header>
                <Banner title={title} description={description} />
            </header>
            <div className="container-perfil pb20">
                <div className="container-menu">
                    <MenuPerfil />
                </div>
                <div className="container-box">
                    {children}
                </div>
            </div>
            <Footer />
        </>
    );
}
