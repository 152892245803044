import React, { useState, useContext } from 'react';
import { Toolbar, Drawer, List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AuthContext } from "../../contexts/auth";
import { useNavigate } from 'react-router-dom';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import "./MenuIcone.css";

export default function Menu({ photo }) {
    const [open, setOpen] = useState(false);
    const [openSubmenu1, setOpenSubmenu1] = useState(false);
    const [openSubmenu2, setOpenSubmenu2] = useState(false);
    const [openSubmenu3, setOpenSubmenu3] = useState(false);
    const [openSubmenu4, setOpenSubmenu4] = useState(false);
    const [openSubmenu5, setOpenSubmenu5] = useState(false);
    const { logout } = useContext(AuthContext);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleSubmenuClick1 = () => {
        setOpenSubmenu1(!openSubmenu1);
        setOpenSubmenu2(false);
        setOpenSubmenu3(false);
        setOpenSubmenu4(false);
        setOpenSubmenu5(false);
    };

    const handleSubmenuClick2 = () => {
        setOpenSubmenu2(!openSubmenu2);
        setOpenSubmenu1(false);
        setOpenSubmenu3(false);
        setOpenSubmenu4(false);
        setOpenSubmenu5(false);
    };

    const handleSubmenuClick3 = () => {
        setOpenSubmenu3(!openSubmenu3);
        setOpenSubmenu1(false);
        setOpenSubmenu2(false);
        setOpenSubmenu4(false);
        setOpenSubmenu5(false);
    };

    const handleSubmenuClick4 = () => {
        setOpenSubmenu4(!openSubmenu4);
        setOpenSubmenu1(false);
        setOpenSubmenu2(false);
        setOpenSubmenu3(false);
        setOpenSubmenu5(false);
    };

    const handleSubmenuClick5 = () => {
        setOpenSubmenu5(!openSubmenu5);
        setOpenSubmenu1(false);
        setOpenSubmenu2(false);
        setOpenSubmenu3(false);
        setOpenSubmenu4(false);
    };

    const handleLogout = () => {
        logout();
    };

    let navigate = useNavigate();
    const routeToHome = () => navigate('/home');
    const routeToPerfil = () => navigate('/perfil');
    const routeToOportunidades = () => navigate('/oportunidades');
    const routeToUpdatePassword = () => navigate("/updatePassword");
    const routeToCertificacao = () => window.open('https://certificado-labora.thinkific.com/', '_blank');
    const routeToJornadaLabora = () => navigate("/jornadaLabora");
    const routeToDisponibilidade = () => navigate("/disponibilidade");
    const routeToProcSeletivos = () => navigate("/procSeletivos");
    const routeToJornadasRealizadas = () => navigate("/jornadasRealizadas");
    const routeToLab60 = () => window.open("https://www.lab60.org/", "_blank");
    const routeToMei = () => navigate("/mei");
    const routeToRoleplays = () => navigate("/roleplays");
    const routeToRanking = () => navigate("/ranking");
    const routeToAgenda = () => navigate("/agenda");
    const routeToAppLabora = () => navigate("/appLabora");
    const routeToOnboarding = () => navigate("/onboarding");
    const routeToDashboard = () => navigate("/dashboard");
    const routeToFinanceiro = () => navigate("/financeiro");
    const routeToReskiling = () => navigate("/reskiling");
    const routeToFlexLabora = () => navigate("/flexLabora");
    const routeToEventos = () => navigate("/eventos");
    const routeToMoedasLabora = () => navigate("/moedasLabora");
    const routeToHubLabora = () => navigate("/hubLabora");
    const routeToFormatura = () => navigate("/formatura");
    const routeToBemVindos = () => navigate("/bemVindos");
    const routeToMentorias = () => navigate("/mentorias");
    const routeToAdvocacy = () => navigate("/advocacy");
    const routeToCulturaLabora = () => navigate("/culturaLabora");
    const routeToEspecializacoes = () => navigate("/especializacoes");
    const routeToTrabalhoFlex = () => navigate("/trabalhoFlex");
    const routeToComecePorAqui = () => navigate("/comecePorAqui");
    // const routeToEmpresas = () => navigate("/empresas");
    // const routeToCertificados = () => navigate("/certificados");

    let icone = true;
    let foto = false;
    if (photo !== null && photo !== "") {
        icone = false;
        foto = true;
    }

    return (
        <div>
            <Toolbar>
                {icone &&
                    <AccountCircleTwoToneIcon
                        className="icon-user"
                        id="basic-menu"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={toggleDrawer} />}

                {foto &&
                    <img
                        className="banner-foto"
                        id="basic-menu"
                        src={photo}
                        alt="foto"
                        onClick={toggleDrawer} />}
            </Toolbar>

            <Drawer anchor="left" open={open} onClose={toggleDrawer}>
                <List className="menu_icone">
                    <ListItem className="menu_icone_item" onClick={routeToHome}>
                        <ListItemText primary="Plataforma Labora" />
                    </ListItem>
                    <ListItem className="menu_icone_item" onClick={handleSubmenuClick1}>
                        <ListItemText primary="Reskilling" />
                        {openSubmenu1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSubmenu1} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Comece por aqui" onClick={routeToComecePorAqui} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Meus Certificados" onClick={routeToJornadaLabora} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Aprenda como abrir uma MEI" onClick={routeToMei} />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem className="menu_icone_item" onClick={handleSubmenuClick2}>
                        <ListItemText primary="Back 2 the game" />
                        {openSubmenu2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSubmenu2} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Especializações" onClick={routeToEspecializacoes} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Oportunidades" onClick={routeToOportunidades} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Agenda de eventos" onClick={routeToAgenda} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Roleplays / Avaliações" onClick={routeToRoleplays} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Ranking" onClick={routeToRanking} />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem className="menu_icone_item" onClick={handleSubmenuClick3}>
                        <ListItemText primary="Flex Labora" />
                        {openSubmenu3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSubmenu3} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Trabalho Flex" onClick={routeToTrabalhoFlex} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Disponibilidade" onClick={routeToDisponibilidade} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Baixe o app" onClick={routeToAppLabora} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Onboarding dos projetos" onClick={routeToOnboarding} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Dashboard do projeto" onClick={routeToDashboard} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Minha jornada" onClick={routeToJornadasRealizadas} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Meu financeiro" onClick={routeToFinanceiro} />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem className="menu_icone_item" onClick={handleSubmenuClick4}>
                        <ListItemText primary="Eu na Comunidade" />
                        {openSubmenu4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSubmenu4} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Reskiling" onClick={routeToReskiling} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Processos Seletivos" onClick={routeToProcSeletivos} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Flex Labora" onClick={routeToFlexLabora} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Meu perfil" onClick={routeToPerfil} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Alterar senha" onClick={routeToUpdatePassword} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Cultura Labora" onClick={routeToCulturaLabora} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Eventos" onClick={routeToEventos} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Moedas Labora" onClick={routeToMoedasLabora} />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem className="menu_icone_item" onClick={handleSubmenuClick5}>
                        <ListItemText primary="Encontros da rede" />
                        {openSubmenu5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSubmenu5} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Lab60+" onClick={routeToLab60} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Hub Labora" onClick={routeToHubLabora} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Formatura" onClick={routeToFormatura} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Bem vindos" onClick={routeToBemVindos} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Mentorias" onClick={routeToMentorias} />
                            </ListItem>
                            <ListItem className="menu_icone_subitem" sx={{ pl: 4 }}>
                                <ListItemText primary="Advocacy" onClick={routeToAdvocacy} />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem sx={{ cursor: "pointer" }} onClick={handleLogout} >
                        <ListItemText primary="Sair" />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}

