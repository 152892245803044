import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
// import moment from "moment";

// import { useForm } from "react-hook-form";


export default function AdicionarEvento({ onAdicionar, onClose }) {
    const [novoEvento, setNovoEvento] = useState({
        id: "",
        title: "",
        start: "",
        end: "",
    });

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            const { name } = e.target;
            setNovoEvento({ ...novoEvento, [name]: "" });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if ((novoEvento.start === "08:00" && novoEvento.end === "12:00") || (novoEvento.start === "09:00" && novoEvento.end === "13:00")) {
            setNovoEvento({ ...novoEvento, [name]: value, title: "", color: "#f7a600" });
        } else if ((novoEvento.start === "12:00" && novoEvento.end === "16:00") || (novoEvento.start === "13:00" && novoEvento.end === "17:00")) {
            setNovoEvento({ ...novoEvento, [name]: value, title: "", color: "#701c7f" });
        } else {
            setNovoEvento({ ...novoEvento, [name]: value, title: "", color: "#888888" });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (novoEvento.start && novoEvento.end) {
            const id = novoEvento.id;
            const title = novoEvento.title;
            const start = novoEvento.start;
            const end = novoEvento.end;
            console.log("Start: ", start, " - ", typeof (start), "End: ", end, " - ", typeof (end));
            if (start >= end) {
                alert("Data/hora de início deve ser inferior à Data/hora de término !!!");
                return;
            }
            onAdicionar(novoEvento);
            setNovoEvento({
                id: id,
                title: title,
                start: start,
                end: end,
            });
            // setFormKey(formKey + 1); // Atualiza a chave do formulário para forçar a recriação
            console.log('Evento adicionado e estado redefinido'); // Log de depuração
            // const newEvent = { title: novoEvento.title, start: `${startDate.toString()}`, end: `${endDate.toString()}` };
        };
    };

    const handleClose = () => {
        onClose();
    }


    return (
        // <div className="adicionar">
        <div>
            {/* <h3>Adicionar</h3>
            <h3>disponibilidade</h3> */}
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="modal">
                        <div className="modal-content">
                            <h3>Agendar disponibilidade</h3>
                            <div className="modal-campos">
                                <p>Início</p>
                                <TextField
                                    className="textfield-2b"
                                    type="time"
                                    id="start"
                                    name="start"
                                    variant="outlined"
                                    size="small"
                                    value={novoEvento.start}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                <p>Final</p>
                                <TextField
                                    // label="P1-Término"
                                    className="textfield-2b"
                                    type="time"
                                    id="end"
                                    name="end"
                                    variant="outlined"
                                    size="small"
                                    value={novoEvento.end}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                <div className="botao-calendario centro pt20">
                                    <Button
                                        className="botao-calendar"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClose}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        className="botao-calendar"
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!novoEvento.start || !novoEvento.end}
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}