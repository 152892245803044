import React, { useEffect, useState } from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import { readOportunidades } from "../services/api";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ModalOportunidades from "../components/ModalOportunidades/ModalOportunidades.jsx";
import MsgModal from "../components/MsgModal/MsgModal.jsx";

export default function Oportunidades() {
    const token = localStorage.getItem("token");
    const [oportunidadesDataResponse, setOportunidadesDataResponse] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        async function fetchOportunidades() {
            const oportunidadesResponse = await readOportunidades(token);
            setOportunidadesDataResponse(oportunidadesResponse);
            setLoaded(true);
            console.log("oportunidadesResponse no perfil123", oportunidadesResponse);
        }
        fetchOportunidades();
    }, [token]);

    // console.log("oportunidadesDataResponse", oportunidadesDataResponse);
    // console.log("var loaded", loaded);

    if (!loaded) {
        return <div>Carregando Oportunidades...</div>;
    } else {
        return (
            <>
                {/* <div className="container-header">
                    <header className="app-header">
                        <Banner title="Oportunidades" description="Oportunidades Labora Tech." />
                    </header>
                </div>*/}
                <div className="container-perfil pb40">
                    {/*<div className="container-menu">
                        <MenuPerfil></MenuPerfil>
                    </div>*/}
                    <div className="box-oportunidades pb20"> 
                        <p className="p-box">Sabemos que você busca oportunidades de trabalho que reconheçam e valorizem seus
                            talentos, certo?</p>
                        <p className="p-box">Ao conhecer você, seu propósito e suas competências certificadas, construímos trabalhos,
                            em parceria com empresas e instituições comprometidas com inclusão da diversidade.</p>
                        <h1>Conheça abaixo as oportunidades:</h1>

                        <Box sx={{ flexGrow: 1, width: 1, height: 1, m: "auto", p: "auto" }}>
                            <Grid
                                className="grid-container"
                                container
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {
                                    oportunidadesDataResponse.data.rows.map((oportunidade, index) => (
                                        <Grid key={index} className="grid-item" item xs={4} sm={4} md={6} lg={8}>
                                            <img
                                                src={oportunidade.link_banner}
                                                className="image-oportunidade"
                                                alt="imagem oportunidade"
                                            />
                                            <h4>{oportunidade.titulo}</h4>
                                            <p>{oportunidade.descricao_curta}</p>
                                            <MsgModal dataMsg={oportunidade} />
                                            <ModalOportunidades data={oportunidade} />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                     </div>
                </div>
                {/*<Footer></Footer> */}
            </>
        );
    }
}
