export const meses = [
  { value: "Janeiro", label: "Janeiro" },
  { value: "Fevereiro", label: "Fevereiro" },
  { value: "Março", label: "Março" },
  { value: "Abril", label: "Abril" },
  { value: "Maio", label: "Maio" },
  { value: "Junho", label: "Junho" },
  { value: "Julho", label: "Julho" },
  { value: "Agosto", label: "Agosto" },
  { value: "Setembro", label: "Setembro" },
  { value: "Outubro", label: "Outubro" },
  { value: "Novembro", label: "Novembro" },
  { value: "Dezembro", label: "Dezembro" },
];

export const estadoss = [
  { value: "Acre", label: "Acre" },
  { value: "Alagoas", label: "Alagoas" },
  { value: "Amapá", label: "Amapá" },
  { value: "Amazonas", label: "Amazonas" },
  { value: "Bahia", label: "Bahia" },
  { value: "Ceará", label: "Ceará" },
  { value: "Distrito Federal", label: "Distrito Federal" },
  { value: "Espírito Santo", label: "Espírito Santo" },
  { value: "Goiás", label: "Goiás" },
  { value: "Maranhão", label: "Maranhão" },
  { value: "Mato Grosso", label: "Mato Grosso" },
  { value: "Mato Grosso do Sul", label: "Mato Grosso do Sul" },
  { value: "Minas Gerais", label: "Minas Gerais" },
  { value: "Pará", label: "Pará" },
  { value: "Paraíba", label: "Paraíba" },
  { value: "Paraná", label: "Paraná" },
  { value: "Pernambuco", label: "Pernambuco" },
  { value: "Piauí", label: "Piauí" },
  { value: "Rio de Janeiro", label: "Rio de Janeiro" },
  { value: "Rio Grande do Norte", label: "Rio Grande do Norte" },
  { value: "Rio Grande do Sul", label: "Rio Grande do Sul" },
  { value: "Rondônia", label: "Rondônia" },
  { value: "Roraima", label: "Roraima" },
  { value: "Santa Catarina", label: "Santa Catarina" },
  { value: "São Paulo", label: "São Paulo" },
  { value: "Sergipe", label: "Sergipe" },
  { value: "Tocantins", label: "Tocantins" },
];

export const generos = [
  { value: "Masculino", label: "Masculino" },
  { value: "Feminino", label: "Feminino" },
  { value: "Não-binário", label: "Não-binário" },
  { value: "Outros", label: "Outros" },
  { value: "Prefiro não responder", label: "Prefiro não responder" },
];

export const racas = [
  { value: "Amarelo", label: "Amarelo(a)" },
  { value: "Indígena", label: "Indígena" },
  { value: "Branco", label: "Branco(a)" },
  { value: "Pardo", label: "Pardo(a)" },
  { value: "Preto", label: "Preto(a)" },
  { value: "Prefiro não responder", label: "Prefiro não responder" },
];

export const lgbts = [
  { value: "Sim", label: "Sim" },
  { value: "Não", label: "Não" },
  { value: "Prefiro não responder", label: "Prefiro não responder" },
];

export const transs = [
  { value: "Sim", label: "Sim" },
  { value: "Não", label: "Não" },
  { value: "Prefiro não responder", label: "Prefiro não responder" },
];

export const deficientes = [
  { value: "Não", label: "Não" },
  { value: "Deficiência física", label: "Deficiência física" },
  { value: "Deficiência auditiva", label: "Deficiência auditiva" },
  { value: "Deficiência intelectual", label: "Deficiência intelectual" },
  { value: "Deficiência visual", label: "Deficiência visual" },
  { value: "Deficiência múltipla", label: "Deficiência múltipla" },
  { value: "Outras", label: "Outras" },
  { value: "Prefiro não responder", label: "Prefiro não responder" },
];

export const formacoes = [
  { value: "Ensino médio", label: "Ensino médio" },
  { value: "Superior completo", label: "Superior completo" },
  { value: "Superior incompleto", label: "Superior incompleto" },
  { value: "Pós-graduação", label: "Pós-graduação" },
  { value: "Mestrado", label: "Mestrado" },
  { value: "Doutorado", label: "Doutorado" },
];

export const getAnos = () => {
  const anos = [];
  for (let ano = 1940; ano <= 2030; ano++) {
    anos.push(ano);
  }
  return anos;
};

export const getDias = () => {
  const dias = [];
  for (let dia = 1; dia <= 31; dia++) {
    dias.push(dia);
  }
  return dias;
};

export const mesDisponibilidade = [
  { value: 0, label: "Janeiro" },
  { value: 1, label: "Fevereiro" },
  { value: 2, label: "Março" },
  { value: 3, label: "Abril" },
  { value: 4, label: "Maio" },
  { value: 5, label: "Junho" },
  { value: 6, label: "Julho" },
  { value: 7, label: "Agosto" },
  { value: 8, label: "Setembro" },
  { value: 9, label: "Outubro" },
  { value: 10, label: "Novembro" },
  { value: 11, label: "Dezembro" },
];