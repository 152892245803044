import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { light } from "@mui/material/styles/createPalette";

const theme = createTheme({
  palette: {
    primary: {
      light: "#888888",
      main: "#575655",
      dark: "#343434",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF6700",
      main: "#E84927",
      dark: "#F7A600",
      contrastText: "#fff",
    },
    third: {
      light: "#9225a5",
      main: "#701C7F",
      dark: "#551561",
      contrastText: "#fff",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();