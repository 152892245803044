import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import { Apple, Android } from "@mui/icons-material";
import imagem from "../assets/images/lab60.png";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";

export default function AppLabora() {
	const routeToIos = () => window.open("https://wa.me/5511994049286", "_blank", "noreferrer");
	const routeToAndroid = () => window.open("https://wa.me/5511994049286", "_blank", "noreferrer");
	const routeToWebApp = () => window.open("https://webapp.labora.tech", "_blank", "noreferrer");
	return (
		<>
			{/* <header>
				<Banner title="App Labora" description="Página em desenvolvimento" />
			</header>*/}

			<div className="container-perfil">
			{/*	<div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div> */}
				<div className="container-box">
					<h1 className="reinvencao_explicacao pt20">Se você chegou até aqui depois de ter passado toda a trilha e
						foi convidado a trabalhar, preencheu sua disponibilização e contrato, agora precisa baixar o aplicativo
						de embarque/desembarque da jornada flex.</h1>
					{/* <img src={imagem} alt="Colmeia" /> */}
					<p className="reinvencao_explicacao centro">Baixe o app labora para começar o trabalho flex:</p>
					<div className="botao pb40">
						<StyledBtn
							className="btn-banner"
							variant="contained"
							color="third"
							onClick={routeToIos}
							disabled
						>
							{<Apple />}
							IOS
						</StyledBtn>
						<StyledBtn
							className="btn-banner"
							variant="contained"
							color="success"
							onClick={routeToAndroid}
							disabled
						>
							{<Android />}
							Android
						</StyledBtn>
					</div>
					<p className="reinvencao_explicacao centro">Caso queira operar pela web clique aqui:</p>
					<StyledBtn
						className="btn-banner"
						variant="contained"
						color="secondary"
						onClick={routeToWebApp}
					>
						WebApp
					</StyledBtn>
				</div>
			</div>
			{/* <Footer /> */}
		</>
	);
}
