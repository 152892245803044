import React, { useState } from "react";
import { Button, TextField } from "@mui/material";

// import { useForm } from "react-hook-form";
// export const periodo = (value) => {
//     const etapa 
// };

export default function Adicionar({ onAdicionar, onClose }) {
    const [novoEvento, setNovoEvento] = useState({
        id: Date.now().toString(),
        title: "",
        diaDaSemana: "",
        start: "",
        end: "",
        color: "#f7a600"
    });

    //Incluído
    const [formKey, setFormKey] = useState(0); // Adiciona uma chave para forçar a recriação do formulário

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            const { name } = e.target;
            setNovoEvento({ ...novoEvento, [name]: "" });
        }
    };
    //Incluído

    const handleChange = (e) => {
        const { name, value } = e.target;
        if ((novoEvento.start === "08:00" && novoEvento.end === "12:00") || (novoEvento.start === "09:00" && novoEvento.end === "13:00")) {
            setNovoEvento({ ...novoEvento, [name]: value, title: "", color: "#f7a600" });
        } else if ((novoEvento.start === "12:00" && novoEvento.end === "16:00") || (novoEvento.start === "13:00" && novoEvento.end === "17:00")) {
            setNovoEvento({ ...novoEvento, [name]: value, title: "", color: "#701c7f" });
        } else {
            setNovoEvento({ ...novoEvento, [name]: value, title: "", color: "#888888" });
        }
    };
    console.log("NovoEvento_handleChange: ", novoEvento);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (novoEvento.start && novoEvento.end && novoEvento.diaDaSemana) {
            const id = novoEvento.id;
            const title = novoEvento.title;
            const diaDaSemana = novoEvento.diaDaSemana;
            const start = novoEvento.start;
            const end = novoEvento.end;
            const color = novoEvento.color;

            // const startDate = new Date(novoEvento.start);
            // const endDate = new Date(novoEvento.end);
            // console.log("Início: ", startDate, "Final", endDate);
            // if (startDate >= endDate) {
            // console.log("Start: ", start, " - ", typeof (start), "End: ", end, " - ", typeof (end));
            if (start >= end) {
                alert("Data/hora de início deve ser inferior à Data/hora de término !!!");
                return;
            }
            onAdicionar(novoEvento);
            setNovoEvento({
                // title: "",
                // start: "",
                // end: "",
                id: id,
                title: title,
                diaDaSemana: diaDaSemana,
                start: start,
                end: end,
                color: color,
                // color: "#ff9933",
            });
            setFormKey(formKey + 1); // Atualiza a chave do formulário para forçar a recriação
            // console.log('Evento adicionado e estado redefinido'); // Log de depuração
            // const newEvent = { title: novoEvento.title, start: `${startDate.toString()}`, end: `${endDate.toString()}` };
        };
    };

    const diaDaSemanaOptions = ["segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado", "domingo"];

    const handleClose = () => {
        onClose();
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="modal">
                        <div className="modal-content">
                            <h3>Agendar disponibilidade</h3>
                            <div className="modal-campos">
                                <p>Dia da semana</p>
                                <TextField
                                    className="textfield-2b"
                                    select
                                    id="diaDaSemana"
                                    name="diaDaSemana"
                                    variant="outlined"
                                    size="small"
                                    value={novoEvento.diaDaSemana}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    SelectProps={{ native: true, }}
                                >
                                    <option value=""> </option>
                                    {diaDaSemanaOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>

                                    ))}
                                </TextField>
                                <p>Início</p>
                                <TextField
                                    className="textfield-2b"
                                    type="time"
                                    id="start"
                                    name="start"
                                    variant="outlined"
                                    size="small"
                                    value={novoEvento.start}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                <p>Término</p>
                                <TextField
                                    // label="P1-Término"
                                    className="textfield-2b"
                                    type="time"
                                    id="end"
                                    name="end"
                                    variant="outlined"
                                    size="small"
                                    value={novoEvento.end}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                <div className="botao-calendario centro pt20">
                                    <Button
                                        className="botao-calendar"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClose}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        className="botao-calendar"
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!novoEvento.start || !novoEvento.end || !novoEvento.diaDaSemana}
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form >
        </div >
    )
}