import axios from 'axios';
import { baseUrl, urlUserLogin, urlGetUser, urlGetOportunidades, urlUpdatePassword, urlUpdateTempPass, 
  urlGetJornadaLabora, urlGetProcSeletivos, urlGetEmpresas, urlCertificados, urlGetJornadasRealizadas, 
  urlDisponibilidade, urlDeleteDisponibilidade, urlUpdateDisponibilidade } from '../utils/Utils';
export const api = axios.create({
  baseURL: baseUrl,
});

export const createSession = async (email, password, captcha) => {
  const response = await api.post(urlUserLogin, {
    email,
    password,
    captcha,
  }).catch(error => {
    console.error("error createSession: ", error);
    return {
      status: "error",
      msg: error.response.data.msg
      };
  });
  return response;
};

export const readUser = async (token) => {
  const response = await api.get(urlGetUser, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateUser = async (token, data) => {
  data.append(`termo_de_uso`, 'Aceito');
  return await api.put(urlGetUser, data, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const readOportunidades = async (token) => {
  const response = await api.get(urlGetOportunidades, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updatePassword = async (token, data ) => {
  return await api.put(urlUpdatePassword, data, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const updateTempPassword = async (token, data ) => {
  return await api.put(urlUpdateTempPass, data, {
    headers: {
      Accept: 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const readJornadaLabora = async (token) => {
  const response = await api.get(urlGetJornadaLabora, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readProcSeletivos = async (token) => {
  const response = await api.get(urlGetProcSeletivos, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readEmpresas = async (token) => {
  const response = await api.get(urlGetEmpresas, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readCertificados = async (token) => {
  const response = await api.get(urlCertificados, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readJornadasRealizadas = async (token) => {
  const response = await api.get(urlGetJornadasRealizadas, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const readDisponibilidade = async (token, filter) => {
  const page = 1;
  const perPage = 500;
  const startDate = filter.startDate ? filter.startDate : '';
  const endDate = filter.endDate ? filter.endDate : '';
    const response = await api.get(`${urlDisponibilidade}/?perPage=${perPage}page=${page}&startDate=${startDate}&endDate=${endDate}`, {      
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    console.log("Response: ", response);
    return response;
};

export const createDisponibilidade = async (token, data) => {
  const response = await api.post(urlDisponibilidade, data, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateDisponibilidade = async (token, id, data) => {
  // const response = await api.put(urlDisponibilidade, data, {
    const response = await api.put(`${urlUpdateDisponibilidade}/${id}`, data, {
      headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const deleteDisponibilidade = async (token, id) => {
  console.log("URL: ", `${urlDeleteDisponibilidade}/id/${id}`);
  console.log("ID: ", id);
  const response = await api.delete(`${urlDeleteDisponibilidade}/id/${id}`, {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-access-token': token,
      'Content-Type': 'application/json',
    },
  });
  return response;
};
