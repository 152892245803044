export const feriados = [
    new Date(2024, 0, 1), // Ano novo
    new Date(2024, 0, 25), // Aniversário de SP
    new Date(2024, 1, 13), // Carnaval
    new Date(2024, 2, 29), // Paixão de Cristo
    new Date(2024, 3, 21), // Tiradentes
    new Date(2024, 4, 1), // Dia do Trabalho
    new Date(2024, 4, 30), // Corpus Christi
    new Date(2024, 8, 7), // Independência do Brasil
    new Date(2024, 9, 12), // Nossa Senhora Aparecida
    new Date(2024, 10, 2), // Finados
    new Date(2024, 10, 15), // Proclamação da República
    new Date(2024, 10, 20), // Consciência Negra
    new Date(2024, 11, 25), // Natal
    new Date(2025, 0, 1), // Ano novo
    new Date(2025, 0, 25), // Aniversário de SP
    new Date(2025, 2, 4), // Carnaval
    new Date(2025, 3, 18), // Paixão de Cristo
    new Date(2025, 3, 21), // Tiradentes
    new Date(2025, 4, 1), // Dia do Trabalho
    new Date(2025, 5, 19), // Corpus Christi
    new Date(2025, 8, 7), // Independência do Brasil
    new Date(2025, 9, 12), // Nossa Senhora Aparecida
    new Date(2025, 10, 2), // Finados
    new Date(2025, 10, 15), // Proclamação da República
    new Date(2025, 10, 20), // Consciência Negra
    new Date(2025, 11, 25), // Natal
];
