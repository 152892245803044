import React, { useRef, useState, useEffect } from "react";
import { Button, MenuItem, TextField, Checkbox, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ModalMsg from "../components/ModalMsg/ModalMsg.jsx";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "../services/api.js";
import "./PagesStyles.css";
import PolPrivacidade from "../components/PolPrivacidade/PolPrivacidade";
import Footer from "../components/Footer/Footer.jsx";
import BannerTermoUso from "../components/BannerTermoUso/BannerTermoUso.jsx";
import { cpf } from "cpf-cnpj-validator";
import {
  normalizeCep,
  normalizePhone,
  normalizeCpf,
} from "../components/Mascaras/Mascaras.jsx";
import {
  meses,
  estadoss,
  getAnos,
  getDias,
} from "../components/VariaveisOpcoes/VariaveisOpcoes.jsx";
import avatar from "../assets/images/foto_padrao.jpeg";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";

const REGISTER_URL = "/user/create";


function ValidarCpf(cpf) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não for dígito
  
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false; // Verifica se o CPF tem 11 dígitos ou se todos os dígitos são iguais
    }
  
    let soma = 0;
    let resto;
  
    // Validação do primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
  
    soma = 0;
  
    // Validação do segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
  
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
  
    return true;
  }
  

export default function Cadastro() {

  const [cpfValido, setCpfValido] = useState(true);
  const [cepValido, setCepValido] = useState(true);
  const [phoneValido, setPhoneValido] = useState(true);

  const cpfRef = useRef(null);
  const cepRef = useRef(null);
  const phoneRef = useRef(null);


  let navigate = useNavigate();
  const routeToLogin = () => navigate("/");
  const recaptchaRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nome, setNome] = useState("");
  const [nomeError, setNomeError] = useState(false);
  const [helpNome, setHelpNome] = useState("");
  // const [sobrenome, setSobrenome] = useState("");
  const [sobrenomeError, setSobrenomeError] = useState(false);
  const [helpSobrenome, setHelpSobrenome] = useState("");
  // const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [helpEmail, setHelpEmail] = useState("");
  const [diaError, setDiaError] = useState(false);
  const [helpDia, setHelpDia] = useState("");
  const [mes, setMes] = useState("");
  const [mesError, setMesError] = useState(false);
  const [helpMes, setHelpMes] = useState("");
  // const [ano, setAno] = useState("");
  const [anoError, setAnoError] = useState(false);
  const [helpAno, setHelpAno] = useState("");
  const [cidadeError, setCidadeError] = useState(false);
  const [helpCidade, setHelpCidade] = useState("");
  const [cepError, setCepError] = useState(false);
  const [helpCep, setHelpCep] = useState("");
  const [estados, setEstados] = useState("");
  const [estadoError, setEstadoError] = useState(false);
  const [helpEstado, setHelpEstado] = useState("");
  const [cpfError, setCpfError] = useState(false);
  const [helpCpf, setHelpCpf] = useState("");
  const [foneError, setFoneError] = useState(false);
  const [helpFone, setHelpFone] = useState("");

  useEffect(() => {
    <Cadastro />;
  }, [errMsg, loading, success]);

  const setFormData = (formData, data) => {
    const keys = Object.keys(data);
    // console.log("keys:", keys);
    for (let i in keys) {
      if (typeof data[keys[i]] === "object") {
        for (let j in data[keys[i]]) {
          // console.log(`=> ${keys[i]}[${j}]: ${data[keys[i]][j]}`);
          if (typeof data[keys[i]][j] === "object") {
            formData.append(
              `${keys[i]}[${j}]`,
              JSON.stringify(data[keys[i]][j])
            );
          } else {
            formData.append(`${keys[i]}[${j}]`, data[keys[i]][j]);
          }
        }
      } else {
        //console.log(`${i}
        //console.log(`${i} || ${keys[i]}: ${data[keys[i]]}`);
        formData.append(`${keys[i]}`, data[keys[i]]);
      }
    }
  };

  const handleChange = (event) => {
    setMes(event.target.value);
    setEstados(event.target.value);
    setNome(event.target.value);
  };

  const {
    register, control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }, trigger
  } = useForm({  mode: "onChange", // Validação ao digitar,
                criteriaMode: "all" });

  const phoneValue = watch("phone");
  const cpfValue = watch("cpf");
  const cepValue = watch("cep");

  useEffect(() => {
    setValue("phone", normalizePhone(phoneValue));
  }, [phoneValue, setValue]);

  useEffect(() => {
    setValue("cpf", normalizeCpf(cpfValue));
  }, [cpfValue, setValue]);

  useEffect(() => {
    setValue("cep", normalizeCep(cepValue));
  }, [cepValue, setValue]);

  const watchTermo_de_uso = watch("termo_de_uso");

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const [image, setImage] = useState('');
  const [endImg] = useState(avatar);

  const onSubmit = async (data) => {
    const captcha = await recaptchaRef.current.executeAsync();
    data.captcha = captcha;
    console.log("Data: ", data);
    const dadosFormatados = {
      cadastro1: {
        phone: data.phone,
        city: data.city,
        state: data.state,
        cep: data.cep,
      },
      cadastro2: {
        birthday_day: data.birthday_day,
        birthday_month: data.birthday_month,
        birthday_year: data.birthday_year,
      },
      cadastro3: {
        cpf: data.cpf,
      },
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      captcha: data.captcha,
      termo_de_uso: data.termo_de_uso,
    };

    let formData = new FormData();
    console.log("formData1 ", formData);
    formData.append('filetoupload', image);
    const keys = Object.keys(data);
    setFormData(formData, dadosFormatados);

    for (let i of formData.entries()) {
      console.log("FormData: ", i[0] + ", " + i[1]);
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
    } else {
      setSuccess(true);
      setLoading(false);
    }
    await api
      .post(REGISTER_URL, formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log("res", res);
        if (!loading) {
          setSuccess(true);
          setLoading(false);
        } else {
          setSuccess(false);
          setLoading(true);
        }
        setErrMsg(
          "Você irá receber um e-mail para confirmar o seu cadastro. Após a confirmação do cadastro, você receberá um e-mail com a senha temporária que deverá ser alterada no primeiro acesso"
        );
      })
      .catch((err) => {
        if (err.request) {
        }
        if (!err?.response) {
          setErrMsg("Favor preencher todos os campos obrigatórios");
        } else if (err.response?.status === 409) {
          setErrMsg("Username Taken");
        } else if (err.response?.status === 422) {
          setErrMsg(
            "USUÁRIO JÁ CADASTRADO. Clique em FECHAR e volte para a página de LOGIN para entrar na Labora."
          );
        } else {
          setErrMsg("Registration Failed");
        }
        setSuccess(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    setNomeError(false);
    setHelpNome("");
    setSobrenomeError(false);
    setHelpSobrenome("");
    setCpfError(false);
    setHelpCpf("");
    setFoneError(false);
    setHelpFone("");
    setEmailError(false);
    setHelpEmail("");
    setDiaError(false);
    setHelpDia("");
    setMesError(false);
    setHelpMes("");
    setAnoError(false);
    setHelpAno("");
    setCidadeError(false);
    setHelpCidade("");
    setEstadoError(false);
    setHelpEstado("");
    setCepError(false);
    setHelpCep("");
    // setGeneroError(false);
    // setHelpGenero("");
    // setRacaError(false);
    // setHelpRaca("");
    // setLgbtError(false);
    // setHelpLgbt("");
    // setDeficienteError(false);
    // setHelpDeficiente("");
    // setTransError(false);
    // setHelpTrans("");
    // if (!errors) {
    if (errors.first_name) {
      setNomeError(true);
      if (errors.first_name.types.required) {
        setHelpNome("Digite um nome");
      } else if (errors.first_name.types.maxLength) {
        setHelpNome("Máximo de 15 caracteres alfabéticos");
      } else if (errors.first_name.types.pattern) {
        setHelpNome("Não pode conter números");
      }
    } else if (errors.last_name) {
      setSobrenomeError(true);
      if (errors.last_name.types.required) {
        setHelpSobrenome("Digite um nome");
      } else if (errors.last_name.types.maxLength) {
        setHelpSobrenome("Máximo de 35 caracteres alfabéticos");
      } else if (errors.last_name.types.pattern) {
        setHelpSobrenome("Não pode conter números");
      }
    } else if (errors.cpf) {
      setCpfError(true);
      if (errors.cpf.types.required) {
        setHelpCpf("Digite somente os números");
      } else if (errors.cpf.types.minLength) {
        setHelpCpf("Deve ter 11 números");
      } else if (errors.cpf.types.maxLength) {
        setHelpCpf("Máximo de 11 números");
      } else {
        setHelpCpf("CPF inválido");
      }
    } else if (errors.phone) {
      setFoneError(true);
      if (errors.phone.types.required) {
        setHelpFone("Digite somente os números");
      } else if (errors.phone.types.minLength) {
        setHelpFone("Mínimo de 10 numeros");
      }
    } else if (errors.email) {
      setEmailError(true);
      if (errors.email.types.required) {
        setHelpEmail("Digite o email");
      } else if (errors.email.types.pattern) {
        setHelpEmail("Email inválido");
      }
    } else if (errors.birthday_day) {
      setDiaError(true);
      if (errors.birthday_day.types.required) {
        setHelpDia("Digite o dia DD");
      } else if (errors.birthday_day.types.max) {
        setHelpDia("Dia inválido");
      } else if (errors.birthday_day.types.pattern) {
        setHelpDia("Somente números");
      }
    } else if (errors.birthday_month) {
      setMesError(true);
      setHelpMes("Selecione um mês");
    } else if (errors.birthday_year) {
      setAnoError(true);
      if (errors.birthday_year.types.required) {
        setHelpAno("Digite o ano AAAA");
      } else if (errors.birthday_year.types.min) {
        setHelpAno("Mínimo 1930");
      } else if (errors.birthday_year.types.maxLength) {
        setHelpAno("Ano inválido");
      } else if (errors.birthday_year.types.pattern) {
        setHelpAno("Somente números");
      }
    } else if (errors.city) {
      setCidadeError(true);
      if (errors.city.types.required) {
        setHelpCidade("Digite a cidade");
      } else if (errors.city.types.maxLength) {
        setHelpCidade("Máximo 20 caracteres alfabéticos");
      } else if (errors.city.types.pattern) {
        setHelpCidade("Somente caracteres alfabéticos");
      }
    } else if (errors.state) {
      setEstadoError(true);
      setHelpEstado("Selecione um estado");
    } else if (errors.cep) {
      setCepError(true);
      if (errors.cep.types.required) {
        setHelpCep("Digite somente os números");
      } else if (errors.cep.types.minLength) {
        setHelpCep("Mínimo 8 números");
      } else if (errors.cep.types.maxLength) {
        setHelpCep("Máximo 8 números");
      }
      // } else if (errors.gender) {
      //   setGeneroError(true);
      //   setHelpGenero("Selecione uma opção");
      // } else if (errors.ethnic_group) {
      //   setRacaError(true);
      //   setHelpRaca("Selecione uma opção");
      // } else if (errors.sexual_orientation) {
      //   setLgbtError(true);
      //   setHelpLgbt("Selecione uma opção");
      // } else if (errors.deficiency) {
      //   setDeficienteError(true);
      //   setHelpDeficiente("Selecione uma opção");
      // } else if (errors.transsexual) {
      //   setTransError(true);
      //   setHelpTrans("Selecione uma opção");
    }
  }, [
    errors.first_name,
    errors.last_name,
    errors.cpf,
    errors.phone,
    errors.email,
    errors.birthday_day,
    errors.birthday_month,
    errors.birthday_year,
    errors.city,
    errors.state,
    errors.cep,
    // errors.gender,
    // errors.ethnic_group,
    // errors.sexual_orientation,
    // errors.deficiency,
    // errors.transsexual,
    errMsg,
  ]);

  const handleBlur = async (fieldName, fieldRef) => {
    const isFieldValid = await trigger(fieldName);
    const fieldValue = fieldRef.current.value;
  
    if (!fieldValue) {
      fieldRef.current.focus(); // Mantém o foco se o campo estiver vazio (obrigatório)
    } else if (!isFieldValid) {
      fieldRef.current.focus(); // Mantém o foco se o campo for inválido (formato)
    }
  };

  return (
    <>
      <section>
        <BannerTermoUso></BannerTermoUso>
        <div className="container-cadastro">
          <div className="container-cadastro1">
            <h1 className="h1-cadastro">
              Sua jornada com a Labora começa aqui
            </h1>
            <p className="p-cadastro">
              Inscreva-se abaixo para começarmos a te conhecer (mesmo que você
              já esteja em nossas redes sociais)!
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="box-container">
                <Box sx={{ mb: 2, border: "1px solid #d1d2d2", borderRadius: 1 }} className="header-talento">
                  <Box className="label">
                    <label className="label-talento" htmlFor="Foto">Selecione sua foto de até 5MB e em formato quadrado:</label>
                  </Box>
                  <Box className="box-talento">
                    <Box className="box-imagem">
                      {image ? <img src={URL.createObjectURL(image)} alt="Imagem" className="img-talento" /> :
                        <img src={endImg} alt="Imagem 1" className="img-talento" />}
                    </Box>
                    <Box className="box-imagem">
                      <input 
                        type="file"  
                        accept="image/*" 
                        name="photo" 
                        id="photo" 
                        onChange={e => setImage(e.target.files[0])} 
                        disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                      />
                    </Box>
                  </Box>
                </Box>
                <TextField
                  className="textfield-2 fl"
                  label="Nome"
                  type="text"
                  id="first_name"
                  name="first_name"
                  variant="outlined"
                  size="small"
                  error={nomeError}
                  helperText={helpNome}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("first_name", {
                    required: true,
                    maxLength: 15,
                    pattern: {
                      value: /^[A-Za-zÁÉÍÓÚÂÊÔÃÕÇáéíóúâêôãõç ]+$/,
                    },
                  })}
                />
                <TextField
                  className="textfield-2 fr"
                  label="Sobrenome"
                  type="text"
                  id="last_name"
                  name="last_name"
                  variant="outlined"
                  size="small"
                  error={sobrenomeError}
                  helperText={helpSobrenome}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("last_name", {
                    required: true,
                    maxLength: 35,
                    pattern: {
                      value: /^[A-Za-zÁÉÍÓÚÂÊÔÃÕÇáéíóúâêôãõç ]+$/,
                    },
                  })}
                />
  
  
                <Controller
                  name="cpf"
                  control={control}
                  rules={{
                  required: "CPF é obrigatório",
                  pattern: {
                      value: /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
                      message: "Padrão correto: 999.999.999-99",
                  },
                  validate: (value) => {
                    const normalizedValue = normalizeCpf(value);
                    const isValid = ValidarCpf(normalizedValue);
                    setCpfValido(isValid);
                    return isValid || "CPF inválido";
                  },
                  }}
                  render={({ field }) => (
                    <TextField
                        {...field}
                            inputRef={(ref) => {
                            field.ref(ref);
                            cpfRef.current = ref; // Atribui a referência do campo de CPF
                            }}
                        className="textfield-2 fl"
                        label="CPF"
                        type="text"
                        id="cpf"
                        name="cpf"
                        variant="outlined"
                        size="small"
                        required
                        disabled={!phoneValido || !cepValido} // Desabilita se Telefone ou CEP forem inválidos
                        error={cpfError}
                        helperText={helpCpf}
                        placeholder="Somente números do CPF"
                        {...register("cpf", {
                            setValueAs: (value) => cpf.format(value),
                            validate: (value) => cpf.isValid(value),
                            required: true,
                            minLength: 11,
                            maxLength: 14,
                        })}
                        onChange={(e) => {
                          const value = e.target.value;
                          const normalizedValue = normalizeCpf(value);
                          field.onChange(normalizedValue);
                          const isValid = ValidarCpf(normalizedValue);
                          setCpfValido(isValid); // Atualiza o estado de validade do CPF
                        }}
                        onBlur={() => {
                          const isValid = ValidarCpf(cpfRef.current.value);
                          setCpfValido(isValid);
                          if (!isValid) {
                            cpfRef.current.focus(); // Mantém o foco no campo CPF se for inválido
                          }
                        }}
                    />
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Telefone é obrigatório",
                    pattern: {
                      value: /^\(\d{2}\)\s\d{5}-\d{4}$/, // Padrão de telefone
                      message: "Padrão correto: (99) 99999-9999",
                    },
                    validate: (value) => {
                      const isValid = /^\(\d{2}\)\s\d{5}-\d{4}$/.test(value); // Validação do formato do telefone
                      setPhoneValido(isValid); // Atualiza o estado de validade do telefone
                      return isValid || "Telefone inválido";
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputRef={(ref) => {
                        field.ref(ref);
                        phoneRef.current = ref; // Atribui a referência do campo de Phone
                      }}
                      className="textfield-2 fr"
                      label="Telefone"
                      type="text"
                      id="phone"
                      name="phone"
                      variant="outlined"
                      size="small"
                      required
                      disabled={!cpfValido || !cepValido} // Desabilita se CPF ou CEP forem inválidos
                      error={!!errors?.phone} // Destaca o campo em vermelho se houver erro
                      helperText={errors?.phone?.message} // Exibe a mensagem de erro em vermelho
                      placeholder="Somente números DD999999999"
                      {...register("phone", {
                        // required: true,
                        minLength: 14,
                        maxLength: 15,
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        const normalizedValue = normalizePhone(value); // Normaliza o valor do telefone
                        field.onChange(normalizedValue); // Atualiza o campo com o valor normalizado
                        const isValid = /^\(\d{2}\)\s\d{5}-\d{4}$/.test(normalizedValue); // Verifica a validade do telefone
                        setPhoneValido(isValid); // Atualiza o estado de validade do telefone
                      }}
                      onBlur={() => {
                        const isValid = /^\(\d{2}\)\s\d{5}-\d{4}$/.test(phoneRef.current.value); // Valida o telefone ao perder o foco
                        setPhoneValido(isValid);
                        if (!isValid) {
                          phoneRef.current.focus(); // Mantém o foco no campo de telefone se for inválido
                        }
                      }}
                    />
                  )}
                />
                <TextField
                  className="textfield-1"
                  label="E-mail"
                  type="email"
                  id="email"
                  name="email"
                  variant="outlined"
                  size="small"
                  error={emailError}
                  helperText={helpEmail}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    },
                  })}
                />
                <TextField
                  className="textfield-3 fl"
                  select
                  label="Dia de nascimento"
                  // type="datetime"
                  id="birthday_day"
                  name="birthday_day"
                  variant="outlined"
                  size="small"
                  error={diaError}
                  helperText={helpDia}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("birthday_day", {
                    required: true,
                    // max: 31,
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    // },
                  })}
                >
                  {getDias().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-3"
                  select
                  label="Mês de nascimento"
                  id="birthday_month"
                  name="birthday_month"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={mesError}
                  helperText={helpMes}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("birthday_month", {
                    required: true,
                  })}
                >
                  {meses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-3 fr"
                  select
                  label="Ano de nascimento"
                  // type="datetime"
                  id="birthday_year"
                  name="birthday_year"
                  variant="outlined"
                  size="small"
                  error={anoError}
                  helperText={helpAno}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("birthday_year", {
                    required: true,
                    // maxLength: 4,
                    // min: 1930,
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    // },
                  })}
                >
                  {getAnos().map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-3 fl"
                  label="Cidade"
                  type="text"
                  id="city"
                  name="city"
                  variant="outlined"
                  size="small"
                  error={cidadeError}
                  helperText={helpCidade}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("city", {
                    required: true,
                    maxLength: 20,
                    pattern: {
                      value: /^[A-Za-zÁÉÍÓÚÂÊÔÃÕÇáéíóúâêôãõç ]+$/,
                    },
                  })}
                />
                <TextField
                  className="textfield-3"
                  select
                  label="Estado"
                  id="state"
                  name="state"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={estadoError}
                  helperText={helpEstado}
                  disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                  {...register("state", {
                    required: true,
                  })}
                >
                  {estadoss.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>


                <Controller
                  name="cep"
                  control={control}
                  rules={{
                    required: "CEP é obrigatório",
                    pattern: {
                      value: /^[0-9]{5}-[0-9]{3}$/, // Padrão de CEP
                      message: "Padrão correto: 99999-999",
                    },
                    validate: value => {
                      const isValid = /^[0-9]{5}-[0-9]{3}$/.test(value); // Validação do formato do CEP
                      setCepValido(isValid); // Atualiza o estado de validade do CEP
                      return isValid || "CEP inválido";
                    },
                  }}

                  render={({ field }) => (
                    <TextField
                      {...field}
                      inputRef={(ref) => {
                        field.ref(ref);
                        cepRef.current = ref; // Atribui a referência do campo de CEP
                      }}
                      className="textfield-3 fr"
                      label="CEP"
                      type="text"
                      id="cep"
                      name="cep"
                      variant="outlined"
                      size="small"
                      // error={cepError}
                      // helperText={helpCep}
                      placeholder="Somente números do CEP"
                      required
                      disabled={!cpfValido || !phoneValido} // Desabilita se CPF ou Telefone forem inválidos
                      error={!!errors?.cep}
                      helperText={errors?.cep?.message}
                      {...register("cep")}
                      onChange={(e) => {
                        const value = e.target.value;
                        const normalizedValue = normalizeCep(value); // Normaliza o valor do CEP
                        field.onChange(normalizedValue); // Atualiza o campo com o valor normalizado
                        const isValid = /^[0-9]{5}-[0-9]{3}$/.test(normalizedValue); // Verifica a validade do CEP
                        setCepValido(isValid); // Atualiza o estado de validade do CEP
                      }}
                      onBlur={() => {
                        const isValid = /^[0-9]{5}-[0-9]{3}$/.test(cepRef.current.value); // Valida o CEP ao perder o foco
                        setCepValido(isValid);
                        if (!isValid) {
                          cepRef.current.focus(); // Mantém o foco no campo de CEP se for inválido
                        }
                      }}
                    />
                  )}
                />

                {/* <TextField
                  className="textfield-2 fl"
                  select
                  label="Gênero autodeclarado"
                  id="gender"
                  name="gender"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={generoError}
                  helperText={helpGenero}
                  {...register("gender", {
                    required: true,
                    pattern: {
                      message: "Selecione uma opção",
                    },
                  })}
                >
                  {generos.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-2 fr"
                  select
                  label="Etnia/raça autodeclarada"
                  id="ethnic_group"
                  name="ethnic_group"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={racaError}
                  helperText={helpRaca}
                  {...register("ethnic_group", {
                    required: true,
                    pattern: {
                      message: "Selecione uma opção",
                    },
                  })}
                >
                  {racas.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-2 fl"
                  select
                  label="Você é uma pessoa LGBTQIA+?"
                  id="sexual_orientation"
                  name="sexual_orientation"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={lgbtError}
                  helperText={helpLgbt}
                  {...register("sexual_orientation", {
                    required: true,
                    pattern: {
                      message: "Selecione uma opção",
                    },
                  })}
                >
                  {lgbts.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-2 fr"
                  select
                  label="Você possui alguma deficiência?"
                  id="deficiency"
                  name="deficiency"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={deficienteError}
                  helperText={helpDeficiente}
                  {...register("deficiency", {
                    required: true,
                    pattern: {
                      message: "Selecione uma opção",
                    },
                  })}
                >
                  {deficientes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="textfield-1"
                  select
                  label="Identifica-se como pessoa trans?"
                  id="transsexual"
                  name="transsexual"
                  defaultValue={""}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  error={transError}
                  helperText={helpTrans}
                  {...register("transsexual", {
                    required: true,
                    pattern: {
                      message: "Selecione uma opção",
                    },
                  })}
                >
                  {transs.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}
              </div>
              <div className="centro pt10">
                <PolPrivacidade></PolPrivacidade>
                <div className="pt10 pb10">
                  <p className="termo-uso">
                    Leia e aceite a política de privacidade para concluir o
                    cadastro
                  </p>
                  <Checkbox
                    className="checkbox"
                    size="small"
                    disabled={!cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos
                    {...register("termo_de_uso", { required: true })}
                    type="checkbox"
                    name="termo_de_uso"
                    value="Aceito"
                  />
                  <strong className="strong">
                    Concordo com a política de privacidade Labora
                  </strong>
                </div>
                <div className="pt10 botao centro">
                  {/* {watchTermo_de_uso && ( */}
                  <StyledBtn
                    className="btn-banner"
                    variant="contained"
                    color="third"
                    type="submit"
                    sx={buttonSx}
                    // disabled={loading}
                    disabled={!watchTermo_de_uso || !cpfValido || !phoneValido || !cepValido} // Desabilita se CPF, Telefone ou CEP forem inválidos

                  >
                    Cadastrar
                  </StyledBtn>
                  {/* )} */}
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "92%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  <StyledBtn
                    className="btn-banner"
                    variant="contained"
                    color="secondary"
                    onClick={routeToLogin}
                  >
                    Cancelar
                  </StyledBtn>
                </div>
              </div>
            </form>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdzTBYgAAAAAN-toGlGYaNxFWTyxYwNVuXlXvOd"
              size="invisible"
              hl="pt-BR"
              badge="bottomleft"
            />
          </div>
        </div>
        <Box>{errMsg && <ModalMsg msg={errMsg} />}</Box>
        {errMsg ===
          "Cadastro realizado com sucesso, você irá receber um e-mail com a senha temporária que deverá ser alterada no primeiro acesso" && (
            <ModalMsg msg={errMsg} modalType={"success"} />
          )}
      </section>
      <Footer></Footer>
    </>
  );
}