import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import "./PolPrivacidade.css";
import BannerTermoUso from '../BannerTermoUso/BannerTermoUso';
import { StyledBtn } from "../StyledButton/StyledButton.jsx"

export default function PolPrivacidade() {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <StyledBtn
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleClickOpen('paper')}>
                Política de Privacidade Labora
            </StyledBtn>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                maxWidth="md"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <header><BannerTermoUso /></header>
                        <div className="container-termo">
                            <h1 className="h1-termo barra pt10">Política de Privacidade Labora</h1>
                            <div className="w75">
                                <div className="barra">
                                    <p>
                                        <span className="item">PÓLEN IMPACTO SOCIAL S.A.</span>, sociedade por ações,
                                        com sede na Alameda Vicente Pinzon, nº 54, Conjunto 91, Jardim Paulista, na cidade de São Paulo,
                                        Estado de São Paulo, CEP 04547-130, inscrita no CNPJ/ME sob o nº 32.146.867/0001-46 (“Labora”
                                        ou “nós”), única e exclusiva proprietária dos direitos relacionados à Plataforma Laboratech
                                        (“Plataforma”) apresenta a presente Política de Privacidade. Essa Política de Privacidade
                                        pretende fornecer aos Usuários (“Usuários” ou “você”) uma visão transparente das nossas práticas
                                        relacionadas à coleta, armazenamento e formas de uso de seus dados pessoais.
                                    </p>
                                    <p>
                                        Os termos constantes nesta Política de Privacidade, sempre que usados com a primeira letra em
                                        maiúsculo, terão o significado estabelecido nesta Política de Privacidade, nos Termos de Uso,
                                        ou na Lei 13.709/2018 (“LGPD”). Contudo, caso você ainda tenha qualquer dúvida, sugerimos que
                                        entre em contato conosco através do e-mail contato@labora.tech.
                                    </p>
                                </div>
                                <div className="barra">
                                    <p>
                                        <span className="item">1. INTRODUÇÃO</span>
                                    </p>
                                    <p>
                                        Nós oferecemos uma plataforma para promover a inclusão da diversidade no mercado de trabalho,
                                        oferecendo cursos e intermediando contratações por parceiros cadastrados na Plataforma.
                                    </p>
                                    <p>
                                        Essa Política de Privacidade pretende informá-lo sobre a forma de coleta e Tratamento dos seus
                                        Dados Pessoais.
                                    </p>
                                    <p className="pb20">
                                        Aqui você encontra um pequeno resumo desta Política, mas recomendamos que você leia o documento
                                        completo:
                                    </p>
                                    <p className="resumo barra">
                                        <strong>RESUMO DAS INFORMAÇÕES</strong>
                                    </p>
                                    <table className="tabela1">
                                        <thead> </thead>
                                        <tbody>
                                            <tr>
                                                <th className="barra">Dados coletados</th>
                                                <td className="barra">
                                                    Dados do Usuário inseridos na Plataforma para cadastro; Dados de acesso; Dados
                                                    tratados quando do Acompanhamento Profissional; Dados relacionados aos Cursos;
                                                    Dados fornecidos pelos Usuários para inscrição em Oportunidades.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">Finalidades do tratamento</th>
                                                <td className="barra pt10">
                                                    Cadastro em lista de e-mails, Sugestão de oportunidades de trabalho personalizadas;
                                                    Compartilhamento com Parceiros para seleção de candidatos aptos a prestarem Serviços;
                                                    Mapeamento de Habilidades; Acompanhamento profissional; Comunicações e alertas pela
                                                    Labora; Aprimoramento e proteção da Plataforma.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">Agentes de tratamento</th>
                                                <td className="barra pt10">
                                                    <strong>PÓLEN IMPACTO SOCIAL S.A.</strong>
                                                    – Controladora do tratamento. Alameda Vicente Pinzon, nº 54, Conjunto 91 - Jardim Paulista,
                                                    São Paulo – SP, CEP 04547-130
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">Bases legais do tratamento</th>
                                                <td className="barra pt10">
                                                    (i) Consentimento;
                                                    <br />
                                                    (ii) Execução de contrato;
                                                    <br />
                                                    (iii) Interesse legítimo;
                                                    <br />
                                                    (iv) Cumprimento de obrigação legal e
                                                    <br />
                                                    (v) Exercício regular de direitos.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">Direitos do Titular</th>
                                                <td className="barra pt10">
                                                    Confirmação de Tratamento; Acesso aos Dados Pessoais; Correção de dados incompletos,
                                                    inexatos ou desatualizados; Eliminação dos Dados Pessoais; Anonimização, bloqueio ou
                                                    eliminação de dados; Portabilidade; Informação sobre a possibilidade de não consentir;
                                                    Oposição ao Tratamento de dados; Revogação de consentimento; e Revisão de decisões
                                                    tomadas exclusivamente com base em Tratamento automatizado de dados.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">Segurança</th>
                                                <td className="barra pt10">
                                                    Medidas técnicas e administrativas apropriadas, considerando a natureza do tratamento e
                                                    sensibilidade dos dados.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">Contato e informações</th>
                                                <td className="barra pt10">
                                                    <strong>PÓLEN IMPACTO SOCIAL S.A.</strong>
                                                    <br />
                                                    E-mail: [contato@labora.tech]
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>
                                        Ao se cadastrar na Labora, você declara e concorda que está ciente de todas as disposições desta
                                        Política de Privacidade. Logo, sugerimos que você leia com atenção esse documento, bem como as
                                        suas eventuais atualizações, antes de tomar a decisão de usar ou prosseguir com o uso da
                                        Plataforma. Caso não concorde com qualquer disposição desta Política, você não deve utilizar a
                                        nossa Plataforma.
                                    </p>
                                </div >
                                <div className="barra">
                                    <p className="item barra">
                                        <span>2. DEFINIÇÕES</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="barra">2.1</th>
                                                <td className="barra">
                                                    Nesta Política de Privacidade os termos em maiúsculo serão definidos conforme
                                                    descrito abaixo:
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">i.</th>
                                                <td className="barra pt10">
                                                    <strong>Dados Pessoais: </strong>
                                                    significa, em conjunto, todos os seus dados pessoais, sejam eles dados sensíveis
                                                    ou não, incluindo, mas sem limitação, o nome completo, e-mail, telefone,
                                                    autodeclaração de raça, autodeclaração de gênero, identificação como pessoa LGBTIQ+,
                                                    informação se possui deficiência, entre outros relacionados;
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">ii.</th>
                                                <td className="barra pt10">
                                                    <strong>Encarregado: </strong>
                                                    significa pessoa responsável para atuar como canal de comunicação entre a Labora e você;
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">iii.</th>
                                                <td className="barra pt10">
                                                    <strong>Parceiros: </strong>
                                                    significam empresas inscritas na Labora, as quais divulgam oportunidades de vagas de
                                                    trabalho e prestação de Serviços por meio da Plataforma.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra pt10">iv.</th>
                                                <td className="barra pt10">
                                                    <strong>Plataforma: </strong>
                                                    significa o nosso software de propriedade exclusiva, que oferece a você Ferramentas
                                                    como Cursos, Oportunidades e Acompanhamento Profissional;
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 pb20">2.2</th>
                                                <td className="pt10 pb20">
                                                    Para interpretação desta Política, as palavras no singular devem incluir o plural,
                                                    e o gênero masculino incluirá o feminino e o neutro ou vice-versa.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p>
                                        <span className="item">3. Quais dados a Labora coleta?</span>
                                    </p>
                                    <table className="tabela3">
                                        <thead>
                                            <tr className="tr-header">
                                                <th scope="col">Grupo de dados</th>
                                                <th scope="col">Como são coletados</th>
                                                <th scope="col">Quais dados são coletados e tratados</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Dados do Usuário inseridos na Plataforma para cadastro</th>
                                                <td>Fornecimento pelos Usuários que desejam se cadastrar na Plataforma.</td>
                                                <td>Primeiro Nome;
                                                    <br />
                                                    Sobrenome;
                                                    <br />
                                                    E-mail;
                                                    <br />
                                                    Telefone;
                                                    <br />
                                                    Mês de nascimento;
                                                    <br />
                                                    Ano de nascimento;
                                                    <br />
                                                    Raça;
                                                    <br />
                                                    Gênero;
                                                    <br />
                                                    Orientação sexual;
                                                    <br />
                                                    Informação se é transgênero;
                                                    <br />
                                                    Informação se possui deficiência.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Dados de acesso</th>
                                                <td>Coleta automática de todos os Usuários que acessam a Plataforma.</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Dados tratados quando do Acompanhamento Profissional</th>
                                                <td>Fornecimento pelos Usuários, com uso do App da Labora.</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Dados relacionados aos Cursos</th>
                                                <td>
                                                    Fornecimento pelos Usuários para inscrição nos Cursos, bem como fornecimento por
                                                    terceiros, provedores dos Cursos.
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Dados fornecidos pelos Usuários para inscrição em Oportunidades</th>
                                                <td>Fornecimento pelo Usuário para inscrição nas Oportunidades.</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="pb40 barra">
                                        Periodicamente, a Labora poderá solicitar que você complemente e/ou atualize seus dados, sendo
                                        certo que você sempre terá a opção de fornecê-los ou não.
                                    </p>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>4. COMO A LABORA UTILIZA DADOS PESSOAIS?</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th>4.1</th>
                                                <td>
                                                    Utilizaremos os Dados Pessoais descritos no item acima para as seguintes finalidades
                                                    e de acordo com a base legal aplicável:
                                                </td>
                                            </tr>
                                        </tbody>
                                        <br />
                                    </table>
                                    <table className="tabela3">
                                        <thead>
                                            <tr className="tr-header">
                                                <th scope="col">Finalidade</th>
                                                <th scope="col">Explicação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Cadastro em lista de e-mails</th>
                                                <td>
                                                    A Labora poderá utilizar os seus dados de contato, incluindo e-mail e telefone,
                                                    para se comunicar por meio do envio de notificações, alertas e outros avisos
                                                    relacionados à Labora para utilização da Plataforma.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Sugestão de oportunidades de trabalho personalizadas</th>
                                                <td>
                                                    Poderemos utilizar seus dados, inclusive sensíveis (como de suas características de
                                                    diversidades, como orientação sexual, autodeclaração de transgênero, se você é uma
                                                    pessoa com deficiência), para direcionar Oportunidades na Plataforma.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Compartilhamento com Parceiros para seleção de candidatos aptos a prestarem Serviços</th>
                                                <td>
                                                    Ao mesmo tempo que lhe ofereceremos as Oportunidades de acordo com suas
                                                    características e diversidades, compartilharemos seus dados de Habilidades e
                                                    perfil com Parceiros para que eles possam avaliar se você se encaixa na
                                                    Oportunidade oferecida.
                                                    <br /><br />
                                                    <strong>
                                                        Atenção: Você está ciente e concorda que, sempre que escolher por se candidatar
                                                        a uma Oportunidade, nós compartilharemos seus Dados Pessoais com Parceiros e
                                                        podemos, inclusive, compartilhar Dados Pessoais sensíveis, como sua orientação
                                                        sexual e raça.
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Mapeamento de Habilidades</th>
                                                <td>
                                                    A partir da conclusão de Cursos e dos Serviços que você prestar aos Parceiros, a
                                                    Labora utilizará os seus Dados Pessoais para criar um mapa de suas Habilidades.
                                                    <br /><br />
                                                    As suas Habilidades podem ser compartilhadas com Parceiros, sempre que você se
                                                    candidatar a uma Oportunidade.
                                                    <br /><br />
                                                    Ainda, o resumo de suas Habilidades pode ser acessado por terceiros, Usuários
                                                    da Labora, através do acesso ao seu perfil na Labora.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Acompanhamento Profissional</th>
                                                <td>
                                                    Você poderá inserir Dados na Plataforma fornecendo informações sobre o Serviço
                                                    prestado. A Labora poderá consolidar essas informações e compartilhá-las com os
                                                    Parceiros para que os Parceiros entendam melhor o impacto dos seus Serviços.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Comunicações e alertas pela labora</th>
                                                <td>
                                                    A Labora poderá utilizar os seus dados de contato, incluindo e-mail e telefone,
                                                    para enviar e-mails e comunicações, alertas, notificações e outros avisos
                                                    relacionados à Labora para a utilização da Plataforma.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="barra">Aprimoramento e proteção da Plataforma</th>
                                                <td className="barra">
                                                    A Labora poderá utilizar os Dados Pessoais, incluindo dados de IP para detecção de
                                                    possíveis fraudes e incidentes de segurança na Plataforma.
                                                    <br />
                                                    Além disso, a Labora poderá utilizar os dados de navegação para aprimorar a sua
                                                    experiência em nossa plataforma, bem como usar os logs de acesso para detectar
                                                    erros em nossos sistemas.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="pt10 pb40 barra">4.2</th>
                                                <td className="pt10 pb40 barra">
                                                    Nós trataremos os seus Dados Pessoais com base nas seguintes hipóteses legais: (i)
                                                    consentimento; (ii) quando necessário para executar um contrato do qual você faça
                                                    parte; (iii) com base em nossos interesses legítimos; e (iv) para cumprirmos com
                                                    obrigações legais ou regulatórias; e (v) para exercer os nossos direitos.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>5. COMO ARMAZENAMOS AS INFORMAÇÕES COLETADAS</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="barra">5.1</th>
                                                <td className="barra">
                                                    Sabemos que seus dados são extremamente importantes para você e, consequentemente,
                                                    para nós também. Por isso, a Labora se compromete em fazer todo o possível para
                                                    manter os seus Dados Pessoais sempre seguros, inclusive, irá adotar medidas de
                                                    segurança e de proteção compatíveis com a natureza dos dados coletados, usados e
                                                    armazenados e práticas apropriadas de mercado. No entanto, a Labora não pode
                                                    garantir que tais medidas de segurança sejam isentas de erros ou que não estejam
                                                    sujeitas a interferência de terceiros (hackers, entre outros). Por sua natureza,
                                                    apesar dos melhores esforços da Labora, qualquer medida de segurança pode falhar
                                                    e qualquer dado pode se tornar público. AO UTILIZAR A PLATAFORMA, VOCÊ ENTENDE E
                                                    ASSUME EXPRESSAMENTE ESSE RISCO E CONCORDA QUE A LABORA NÃO SERÁ RESPONSÁVEL POR
                                                    TAL TIPO DE VAZAMENTO DE DADOS.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 barra">5.2</th>
                                                <td className="pt10 barra">
                                                    Todas as informações coletadas serão armazenadas com alto padrão de segurança em
                                                    servidores de terceiros localizados no exterior.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 pb40 barra">5.3</th>
                                                <td className="pt10 pb40 barra">
                                                    Ao utilizar a Plataforma, você está ciente e concorda que nós poderemos contratar
                                                    Suboperadores, nacionais ou estrangeiros, os quais também poderão tratar os seus
                                                    Dados Pessoais. Esses Suboperadores, nacionais ou estrangeiros, estarão
                                                    contratualmente submetidos às mesmas exigências de segurança e confidencialidade
                                                    aplicáveis à Labora.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>6. DIREITOS DOS TITULARES</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="barra">6.1</th>
                                                <td className="barra">
                                                    A Labora disponibiliza ferramentas para que Titulares exerçam os seus direitos
                                                    legais sobre os Dados Pessoais, conforme previstos no artigo 13 da LGPD. Neste
                                                    item descreveremos esses direitos e como você pode exercê-los.
                                                    <br /><br />
                                                    <strong>6.1.1 Confirmação de Tratamento.</strong> Você pode solicitar que
                                                    confirmemos se a Labora trata seus Dados Pessoais.
                                                    <br /><br />
                                                    <strong>6.1.2 Acesso aos Dados Pessoais.</strong> Você pode acessar os seus Dados
                                                    Pessoais, inclusive solicitando cópia dos dados tratados por nós.
                                                    <br /><br />
                                                    <strong>6.1.3 Correção de dados incompletos, inexatos ou desatualizados.</strong>
                                                    Você pode solicitar alteração ou correção dos seus Dados Pessoais que estejam
                                                    incorretos em nossa base de dados.
                                                    <br /><br />
                                                    <strong>6.1.4 Eliminação dos Dados Pessoais.</strong> Você pode solicitar a exclusão
                                                    de seus Dados Pessoais tratados pela Labora quando estes forem coletados e tratados
                                                    com base em seu consentimento, por meio da própria Plataforma ou por solicitação
                                                    pelos canais de atendimento indicados nesta Política.
                                                    <br /><br />
                                                    <strong>6.1.5 Informação sobre compartilhamento.</strong> Você pode solicitar
                                                    informação sobre com quais entidades públicas e privadas nós compartilhamos os
                                                    seus Dados Pessoais, nos termos desta Política.
                                                    <br /><br />
                                                    <strong>6.1.6 Anonimização, bloqueio ou eliminação de dados.</strong> Você pode
                                                    solicitar que a Labora proceda à anonimização, ao bloqueio ou anonimização de Dados
                                                    Pessoais que sejam excessivos.
                                                    <br /><br />
                                                    <strong>6.1.7 Portabilidade.</strong> Você pode solicitar que seus Dados Pessoais
                                                    sejam enviados a outro provedor de serviços.
                                                    <br /><br />
                                                    <strong>6.1.8 Informação sobre a possibilidade de não consentir.</strong> Você
                                                    recebe, por meio da presente Política, e pode solicitar, pelos canais de
                                                    atendimento, informações sobre a possibilidade de não consentir com o Tratamento de
                                                    Dados Pessoais e suas potenciais consequências, inclusive em relação à
                                                    impossibilidade de prestarmos nossos serviços;
                                                    <br /><br />
                                                    <strong>6.1.9 Oposição ao Tratamento de dados.</strong> Você pode opor-se ao
                                                    tratamento de Dados Pessoais que seja fundado em uma base legal que não o
                                                    consentimento.
                                                    <br /><br />
                                                    <strong>6.1.10 Revogação de consentimento.</strong> Você pode revogar o seu
                                                    consentimento para Tratamentos que tenham por base legal o consentimento.
                                                    <br /><br />
                                                    <strong>6.1.11 Revisão de decisões tomadas exclusivamente com base em Tratamento
                                                        automatizado de dados.</strong> Caso a Labora tome qualquer decisão baseada
                                                    exclusivamente em Tratamento de Dados Pessoais automatizados, você pode solicitar
                                                    que essas decisões sejam revistas.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 barra">6.2</th>
                                                <td className="pt10 barra">
                                                    <strong>Exercício de direitos.</strong> Os direitos mencionados acima e outros
                                                    previstos na legislação aplicável podem ser exercidos por você, em relação aos
                                                    seus Dados Pessoais, diretamente pela Plataforma, conforme funcionalidades nela
                                                    disponibilizadas (como por exemplo, ferramentas de acesso e edição de Dados
                                                    Pessoais) ou por meio de solicitação dirigida ao canal [contato@labora.tech].
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 barra">6.3</th>
                                                <td className="pt10 barra">
                                                    <strong>Informação sobre exclusão de dados.</strong> É importante ressaltar que
                                                    poderemos, em razão de obrigações legais ou administrativas, estar impedidos de
                                                    proceder à exclusão de determinados tipos de Dados Pessoais. Nesse caso, quando
                                                    aplicável, nós informaremos você sobre tal impossibilidade, procedendo à exclusão
                                                    das demais informações passíveis de serem apagadas. Sem prejuízo, ao cumprir com
                                                    eventuais solicitações de exclusão, que, quando possível, serão informadas a você,
                                                    poderemos ficar impossibilitados de prestar os nossos serviços, total ou
                                                    parcialmente. Ainda, após a exclusão de Dados Pessoais, nós poderemos continuar a
                                                    usá-los, de forma não individualizada e anonimizada, ou seja, sem qualquer
                                                    identificação pessoal, para os fins previstos nesta Política de Privacidade.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 barra">6.4</th>
                                                <td className="pt10 barra">
                                                    <strong>Retenção de dados.</strong>Nós poderemos guardar os Dados Pessoais de
                                                    certos Usuários por um período superior ao de guarda legal, em cumprimento de
                                                    eventuais ordens de autoridade públicas, para se defender em processos judiciais
                                                    e/ou administrativos e em casos nos quais os Dados Pessoais tenham sido devidamente
                                                    anonimizados.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pt10 pb40 barra">6.5</th>
                                                <td className="pt10 pb40 barra">
                                                    <strong>O não fornecimento de consentimento pelos Usuários</strong> da presente
                                                    Política de Privacidade impossibilita que nós prestemos alguns de nossos serviços.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>7. CONTATO COM A LABORA</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="pb40 barra">7.1</th>
                                                <td className="pb40 barra">
                                                    Em caso de dúvida ou requerimento do exercício de algum dos direitos descritos no item
                                                    6, você poderá entre em contato com o Encarregado, conforme informações abaixo:
                                                    <br /><br />
                                                    Nome: Sergio Serapião <br />
                                                    E-mail: contato@labora.tech
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>8. COMPARTILHAMENTO DE DADOS PARA TERCEIROS</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="pb40 barra">8.1</th>
                                                <td className="pb40 barra">
                                                    Em adição às hipóteses já mencionadas nesta Política de Privacidade, os Dados
                                                    Pessoais poderão também ser transferidos a terceiros, nos seguintes casos:
                                                    <strong>8.1.1 Prestação dos Serviços.</strong> Podemos contratar terceiros que a
                                                    auxiliem na prestação de nossos serviços, como por exemplos, servidores de
                                                    armazenamento em nuvem. Quaisquer outros fornecedores que utilizemos poderão ser
                                                    substituídos a qualquer momento, desde que mantidos padrões adequados de segurança
                                                    e confidencialidade dos dados.
                                                    <br /><br />
                                                    <strong>8.1.2 Novos Negócios.</strong> Poderá haver a transferência de Dados
                                                    Pessoais em processos de aquisição, venda, fusão, reorganização societária ou
                                                    qualquer outra mudança de controle da Labora. Nesse caso, nós iremos garantir a
                                                    que a pessoa, física ou jurídica, que venha a acessar ou assumir o controle sobre
                                                    os dados tratados nos termos desta Política sejam também vinculados a ela. Assim,
                                                    podemos garantir a continuidade da proteção dos seus Dados Pessoais. Finalmente,
                                                    manteremos você informado caso essa transferência implique qualquer alteração na
                                                    Política de Privacidade.
                                                    <br /><br />
                                                    <strong>8.1.3 Requisição Judicial ou Administrativa.</strong> Nós podemos
                                                    compartilhar Dados Pessoais em caso de requisição judicial ou administrativa.
                                                    <br /><br />
                                                    <strong>8.1.4 Com a autorização do Usuário.</strong> Em demais casos, havendo a
                                                    necessidade de compartilhamento das informações, enviaremos a você uma notificação
                                                    solicitando sua aprovação.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>9. OUTROS</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="pb40 barra">9.1</th>
                                                <td className="pb40 barra">
                                                    AO ACEITAR A PRESENTE POLÍTICA, VOCÊ CONCEDE SEU CONSENTIMENTO LIVRE EXPRESSO E
                                                    INFORMADO COM TODAS AS PRÁTICAS MENCIONADAS NESTA POLÍTICA DE PRIVACIDADE.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pb40 barra">9.2</th>
                                                <td className="pb40 barra">
                                                    CASO VOCÊ NÃO TENHA ENTENDIDO QUALQUER PARTE DESTA POLÍTICA DE PRIVACIDADE, você
                                                    deve entrar em contato com a Labora ANTES DE ACEITAR OS TERMOS AQUI PREVISTOS.
                                                    Estamos à disposição para esclarecer qualquer dúvida que os Clientes possam ter,
                                                    por meio do e-mail <mark>contato@labora.tech.</mark>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="pb40 barra">9.3</th>
                                                <td className="pb40 barra">
                                                    Os termos desta Política de Privacidade são regidos e deverão ser interpretados de
                                                    acordo com as Leis da República Federativa do Brasil e conforme disposições gerais dos
                                                    Termos de Uso da Labora, dos quais esta Política de Privacidade é parte integrante.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="item barra">
                                        <span>10. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE</span>
                                    </p>
                                    <table className="tabela2">
                                        <tbody>
                                            <tr>
                                                <th className="pb40 barra">10.1</th>
                                                <td className="pb40 barra">
                                                    Com a finalidade de garantir a privacidade dos Usuários, e a segurança dos seus
                                                    dados, nos comprometemos a regularmente reavaliar essa Política de Privacidade e
                                                    adaptá-la, conforme necessário. Caso sejam implementadas mudanças nesta Política
                                                    de Privacidade, você será notificado e deverá analisar tais alterações. Caso você
                                                    continue a utilizar a Plataforma após anunciarmos uma atualização da Política de
                                                    Privacidade, iremos interpretar que você concordou com as alterações elaboradas
                                                    neste documento. Se você não concordar com as alterações à Política, deve
                                                    abster-se de utilizar a Plataforma.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <p className="barra">
                                        Última atualização, 06 de Abril de 2021.
                                    </p>
                                </div>
                                <div className="centro">
                                    <StyledBtn
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        Fechar
                                    </StyledBtn>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div >
    );
}
