import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #ff9933",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const buttonBold = {
  fontWeight: 700,
  fontSize: 18,
};

export default function ModalMsg({ msg, modalType }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  // console.log("modalType", modalType);

  const handleClose = () => {
    if (modalType === "success") {
      setOpen(false);
      // console.log("fechei o modal1")
      navigate("/");
    } else if (modalType === "editedToHome") {
      setOpen(false);
      navigate("/Home");
    } else if (modalType !== "success") {
      // console.log("fechei o modal2")
      setOpen(false);
      navigate("/");
    }
  };

  // console.log("Open: ", open);
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Aviso
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {msg}
          </Typography>
          <Button sx={buttonBold} onClick={handleClose}>
            Fechar
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
