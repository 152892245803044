import React from "react";
import { useNavigate } from "react-router-dom";
import "./NoPage.css";

export default function Nopage() {
  let navigate = useNavigate();
  const routeToHome = () => navigate("/");
  return (
    <>
      <div id="notfound">
        <div className="notfound-bg"></div>
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>desculpe, mas a página que você solicitou não foi encontrada</h2>
          <a href="/" className="home-btn" onClick={routeToHome}>
            Voltar para o início
          </a>
        </div>
      </div>
    </>
  );
}
